<template>
    <div class="my-chart">
        <div class="filter mb-3">
            <div class="filter-date">
                <b-form-datepicker
                    :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                    }"
                    :locale="this.$root.$i18n.locale === 'vi' ? 'vi' : 'en'"
                    :placeholder="$t('startTime')"
                    v-model="fromDate"
                    class="w-100 bg-white custom-select-day-chart"
                    tabindex="2"
                ></b-form-datepicker>

                <b-form-datepicker
                    :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                    }"
                    :locale="this.$root.$i18n.locale === 'vi' ? 'vi' : 'en'"
                    :placeholder="$t('endTime')"
                    v-model="toDate"
                    class="w-100 bg-white custom-select-day-chart"
                ></b-form-datepicker>
            </div>

            <b-form-select v-model="valueChart" class="mb-3">
                <b-form-select-option
                    v-for="select in arraySelectValue"
                    :key="select.value"
                    :value="select.value"
                    class="custom-select"
                >
                    {{ select.text }}
                </b-form-select-option>
            </b-form-select>
        </div>

        <div class="container-chart">
            <apexchart
                :options="chartOptions"
                :series="series"
                type="line"
                height="350"
                class="chart"
            />
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { mapGetters } from 'vuex';

export default {
    components: {
        apexchart: VueApexCharts,
    },

    data() {
        return {
            valueChart: 'xin',
            arraySelectValue: [
                { value: 'xin', text: 'Xin' },
                { value: 'usdt', text: 'USDT' },
            ],
            fromDate: null,
            toDate: null,
        };
    },

    computed: {
        ...mapGetters({
            MarketStatistics: 'marketplace/MarketStatistics',
            MarketStatisticsDay: 'marketplace/MarketStatisticsDay',
            MarketStatisticsSumDay: 'marketplace/MarketStatisticsSumDay',
        }),

        series() {
            if (!this.MarketStatistics) return [];
            if (!this.MarketStatisticsDay) return [];
            if (!this.MarketStatisticsSumDay) return [];

            const marketStatistics = this.MarketStatistics.filter(
                (ele) => ele._id !== 'voucher',
            );
            const marketStatisticsDay = this.MarketStatisticsDay.filter(
                (ele) => ele._id.itemGroup !== 'voucher',
            );

            const convertName = marketStatistics.map((ele) => ele._id);

            return convertName?.map((ele) => {
                if (ele === 'sum') {
                    const convertData =
                        this.valueChart === 'xin'
                            ? this.MarketStatisticsSumDay.slice(1).map(
                                  (ele) => ele.revenueByXinCoin,
                              )
                            : this.MarketStatisticsSumDay.slice(1).map(
                                  (ele) => ele.revenueByUSDT,
                              );

                    return {
                        name: ele.toUpperCase(),
                        type: 'line',
                        data: convertData,
                    };
                }

                const filterByItemGroup = marketStatisticsDay.filter(
                    (statisticDay) => statisticDay._id.itemGroup === ele,
                );

                const convertData =
                    this.valueChart === 'xin'
                        ? filterByItemGroup.map((ele) => ele.revenueByXinCoin)
                        : filterByItemGroup.map((ele) => ele.revenueByUSDT);

                return {
                    name: ele.toUpperCase(),
                    type: 'column',
                    data: convertData,
                };
            });
        },

        chartOptions() {
            const filterByItemGroup = this.MarketStatisticsDay?.filter(
                (statisticDay) => statisticDay._id.itemGroup === 'nft',
            );

            const categories = filterByItemGroup?.map((ele) => {
                return `${ele._id.day}/${ele._id.month}`;
            });

            return {
                chart: {
                    height: 350,
                    type: 'line',
                    stacked: false,
                    zoom: {
                        enabled: true,
                        type: 'x',
                        allowMouseWheelZoom: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: [1, 1, 4],
                },
                title: {
                    text:
                        this.valueChart === 'usdt'
                            ? this.$t('totalRevenueUsdt')
                            : this.$t('totalRevenueXin'),
                    align: 'left',
                    offsetX: 110,
                },
                xaxis: {
                    categories,
                },
                yaxis: [
                    {
                        seriesName: 'Income',
                        axisTicks: { show: true },
                        axisBorder: { show: true, color: '#008FFB' },
                        labels: {
                            style: { colors: '#008FFB' },
                            formatter: (value) =>
                                `${Number(value).toLocaleString()}`,
                        },
                        title: {
                            text: '',
                            style: { color: '#008FFB' },
                        },
                        tooltip: { enabled: true },
                    },
                ],
                legend: {
                    horizontalAlign: 'center',
                    offsetX: 40,
                },
                tooltip: {
                    y: {
                        formatter: (value) =>
                            `${Number(value).toLocaleString()}`,
                    },
                },
            };
        },
    },

    watch: {
        fromDate(newFrom) {
            const from = newFrom.replace(/-/g, '/');
            const to = this.toDate.replace(/-/g, '/');

            if (this.toDate) {
                this.$store.dispatch('marketplace/getMarketStatistics', {
                    from,
                    to,
                    groupBy: ['itemGroup', 'day'],
                });

                this.$store.dispatch('marketplace/getMarketStatisticsSum', {
                    from,
                    to,
                    groupBy: ['day'],
                });
            }
        },

        toDate(newTo) {
            const from = this.fromDate.replace(/-/g, '/');
            const to = newTo.replace(/-/g, '/');

            if (this.fromDate) {
                this.$store.dispatch('marketplace/getMarketStatistics', {
                    to,
                    from,
                    groupBy: ['itemGroup', 'day'],
                });

                this.$store.dispatch('marketplace/getMarketStatisticsSum', {
                    to,
                    from,
                    groupBy: ['day'],
                });
            }
        },
    },

    mounted() {
        this.$store.dispatch('marketplace/getMarketStatistics');
        this.$store.dispatch('marketplace/getMarketStatistics', {
            groupBy: ['itemGroup'],
            timeRange: 15,
            timeRangeUnit: 'day',
        });
        this.$store.dispatch('marketplace/getMarketStatisticsSum', {
            timeRange: 15,
            timeRangeUnit: 'day',
        });
    },
};
</script>

<style lang="scss" scoped>
.my-chart {
    border: 1px solid #d8d8d8;
    padding: 32px;
    border-radius: 12px;

    .filter {
        display: flex;
        justify-content: space-between;

        .filter-date {
            display: flex;
            width: 50%;
            gap: 16px;
            height: 40px;

            .custom-select-day-chart {
                align-items: center;
                border-radius: 8px;
                height: 100%;
                font-size: 14px;
                color: #667085;
                z-index: 10;

                .form-control {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }

    .container-chart {
        overflow-x: auto;

        .chart {
            min-width: 800px;
            margin-bottom: 6px;
        }
    }

    .custom-select {
        width: 10%;
    }
}

@media (max-width: 768px) {
    .my-chart {
        padding: 16px;

        .filter {
            flex-direction: column;
            gap: 12px;

            .filter-date {
                gap: 12px;
                width: 100%;
            }
        }

        .custom-select {
            width: 30%;
        }
    }
}
</style>
