<template>
    <div>
        <b-modal
            size="md"
            dialog-class="modal-get-otp-global"
            id="modal-get-otp-global"
            centered
            hide-footer
            hide-header
            @hidden="onModalClose"
        >
            <div class="content-otp">
                <div class="form-otp">
                    <form @submit.prevent="debouncedPostOtp" class="form">
                        <div class="img">
                            <img
                                src="../../assets/images/logo/logoV3.png"
                                alt="logo"
                            />
                        </div>

                        <h1>{{ $t('verifyOTP') }}</h1>

                        <div class="otp">
                            <label for="otp">OTP:</label>
                            <div class="input-get-otp">
                                <input
                                    type="text"
                                    placeholder="OTP"
                                    v-model="otp"
                                    id="otp"
                                />

                                <button type="button" @click="debouncedGetOtp">
                                    {{ $t('getOTP') }}
                                </button>
                            </div>

                            <div class="note">
                                {{ $t('warningGetOtp') }}
                            </div>
                        </div>

                        <div
                            :class="`button-confirm-otp ${
                                otp.length <= 0 ? 'disabled' : ''
                            }`"
                        >
                            <button
                                :disabled="otp.length <= 0"
                                class="botan confirm"
                            >
                                {{ $t('confirm') }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
    data() {
        return {
            otp: '',
        };
    },

    computed: {
        ...mapState(['isOpenModal']),
    },

    methods: {
        ...mapActions(['getOTPVerifyToken', 'postOTPVerifyToken']),

        onModalClose() {
            this.$store.commit('onToggleModal', false);
        },

        // eslint-disable-next-line func-names
        debouncedGetOtp: debounce(async function () {
            await this.getOTPVerifyToken();
        }, 300),

        // eslint-disable-next-line func-names
        debouncedPostOtp: debounce(async function () {
            if (this.otp.length <= 0) {
                return;
            }

            const data = await this.postOTPVerifyToken(this.otp);

            if (data.status) {
                const now = moment();
                const expires = moment(data.data.expires);

                const duration = moment.duration(expires.diff(now));

                const totalSeconds = Math.floor(duration.asSeconds());

                window.$cookies.remove('token');
                window.$cookies.set('token', data.data.token, totalSeconds);

                this.otp = '';

                this.$bvModal.hide('modal-get-otp-global');
                this.$toastr.s('Verify Success', 'Successfully');
            }
        }, 300),
    },

    watch: {
        isOpenModal(newValue) {
            if (newValue) {
                this.$bvModal.show('modal-get-otp-global');
            } else {
                this.$bvModal.hide('modal-get-otp-global');
            }
        },
    },
};
</script>

<style lang="scss">
#modal-get-otp-global {
    padding: 0 !important;

    .modal-body {
        border-radius: 12px;
        margin-bottom: 40px;

        .content-otp {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .form-otp {
                .form {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    h1 {
                        font-weight: 600;
                    }

                    .img {
                        width: 200px;
                        height: 200px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .button-confirm-otp {
                        margin-top: 40px;
                        display: flex;
                        width: 100%;

                        .botan {
                            color: #0087cc;
                            border: 1px solid currentColor;
                            border-radius: 100vw;
                            padding: 8px 15px;
                            font-size: 20px;
                            font-weight: 700;
                            flex-grow: 1;
                            transition: all 0.2s ease-in-out;

                            &.confirm {
                                color: #fff;
                                background-color: #0087cc;

                                &:focus {
                                    outline: none;
                                }
                            }

                            &:hover {
                                opacity: 0.8;
                            }
                        }
                    }

                    .button-confirm-otp.disabled {
                        .botan {
                            background-color: gray;
                        }
                    }

                    .otp {
                        display: flex;
                        flex-direction: column;

                        .input-get-otp {
                            display: flex;
                            height: 40px;
                            gap: 16px;

                            input {
                                width: 100%;
                                padding: 0 1rem;
                                border: 1px solid #e4e4e4;
                                border-radius: 12px;
                                height: 40px;
                                color: #000;

                                &:focus {
                                    outline: none;
                                }
                            }

                            button {
                                border-radius: 12px;
                                border: 1px solid #0087cc;
                                background-color: #0087cc;
                                color: white;
                                font-size: 16px;
                                font-weight: 600;
                                padding: 0px 20px;
                                white-space: nowrap;
                                transition: all 0.2s ease-in-out;

                                &:focus {
                                    outline: none;
                                }

                                &:hover {
                                    opacity: 0.8;
                                }
                            }
                        }

                        .note {
                            color: #dc7f33;
                            font-size: 14px;
                            padding: 0px 5px;
                            margin-top: 8px;
                        }
                    }
                }
            }
        }
    }
}
</style>
