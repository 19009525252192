<template>
    <div class="product-container">
        <div class="product-list mt-4">
            <div class="header">
                <h3>{{ $t('productList') }}</h3>

                <div class="inputs">
                    <router-link
                        :to="{ name: 'AddProduct' }"
                        class="add-product"
                    >
                        <!-- {{ $t('addProduct') }} -->
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.16406 9.99935H15.8307M9.9974 4.16602V15.8327"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </router-link>
                </div>
            </div>
            <FilterComponent
                :dispatchGetList="dispatchGetList"
                :commitParams="commitParams"
                :getterParams="mapGettersParams"
            />
            <div
                class="table"
                v-if="
                    SoldItemList &&
                    SoldItemList.results &&
                    SoldItemList.results.length > 0
                "
            >
                <b-table
                    responsive
                    striped
                    hover
                    :items="SoldItemList.results"
                    :fields="fields"
                >
                    <template #empty>
                        <h4 class="text-center my-3">No data</h4>
                    </template>
                    <template #head()="{ label }" style="text-align: center">
                        {{ $t(label) }}
                    </template>
                    <template #cell(name)="data">
                        <strong>
                            {{ data.item.itemData.name }}
                        </strong>
                    </template>
                    <template #cell(thumbnail)="data">
                        <div class="thumbnail">
                            <img :src="data.item.itemData.photoUrl" alt="" />
                        </div>
                    </template>
                    <template #cell(currency)="data">
                        <div v-if="data.item.marketData.currency === 'xinCoin'">
                            XIN
                        </div>
                        <div v-if="data.item.marketData.currency === 'USDT'">
                            USDT
                        </div>
                    </template>
                    <template #cell(date)="data">
                        {{ new Date(data.item.updatedAt).toLocaleDateString() }}
                    </template>
                    <template #cell(price)="data">
                        {{ getPrice(data.item.marketData) }}
                    </template>
                    <template #cell(status)="data">
                        <div
                            class="pending"
                            v-if="data.item.marketData.status === 'AVAILABLE'"
                        >
                            {{ $t('available') }}
                        </div>
                        <div
                            class="success"
                            v-if="data.item.marketData.status === 'SOLD'"
                        >
                            {{ $t('sold') }}
                        </div>
                        <div
                            v-b-tooltip.hover
                            :title="
                                data.item.marketData.status === 'CANCELLED'
                                    ? $t('canceled')
                                    : $t('canceledByAdmin')
                            "
                            class="cancel"
                            v-if="
                                data.item.marketData.status === 'CANCELLED' ||
                                data.item.marketData.status ===
                                    'CANCELLED_BY_ADMIN'
                            "
                        >
                            {{ $t('canceled') }}
                        </div>
                    </template>
                    <template #cell(categories)="data">
                        <div v-if="data.item.itemData.nodeId">XNode</div>
                        <div v-else-if="data.item.itemData.nftNumber">
                            X-NFT
                        </div>
                        <div v-else>Voucher</div>
                    </template>
                    <template #cell(action)="data">
                        <!-- <div class="detail" @click="detailModal(data)">
                            {{ $t('detail') }}
                        </div> -->
                        <MorePopOver
                            :data="data.item"
                            :openDetailModal="() => detailModal(data)"
                            :openConfirmCancelSell="
                                () => openConfirm(data.item)
                            "
                        />
                    </template>
                </b-table>
                <div class="pagination" v-if="SoldItemList.totalPages > 1">
                    <Pagination
                        :page="SoldItemList.page"
                        :totalPages="SoldItemList.totalPages"
                        :handlePrevPage="handlePrevPage"
                        :handleNextPage="handleNextPage"
                    />
                </div>
            </div>
            <EmptyData :list="SoldItemList.results" />
        </div>
        <b-modal id="detail-modal" hide-header hide-footer centered>
            <div v-if="selectedData">
                <div
                    :class="['left', { bg: selectedData.item.itemData.nodeId }]"
                >
                    <img
                        :src="selectedData.item.itemData.photoUrl"
                        alt="img-detail"
                        class="img-detail"
                    />
                </div>
                <div class="right">
                    <div class="info">
                        <!-- <div class="left">
                        <img src="@/assets/images/icons/client.png" alt="" />
                    </div> -->
                        <div class="right">
                            <div class="top">
                                {{ selectedData.item.userId }}
                            </div>
                            <div class="bottom" v-if="UserInfo">
                                {{ UserInfo.email }}
                            </div>
                        </div>
                    </div>
                    <div class="title">
                        {{ selectedData.item.itemData.name }}
                        <span v-if="selectedData.item.itemData.nftNumber"
                            >- {{ selectedData.item.itemData.nftNumber }}</span
                        >
                        <span v-if="selectedData.item.itemData.nodePerformance">
                            -
                            {{ selectedData.item.itemData.nodePerformance }} HZ
                        </span>
                    </div>
                    <div class="detail-info">
                        <div class="price">
                            <div>
                                {{ $t('price') }}:
                                <span
                                    v-if="
                                        selectedData.item.marketData.price
                                            .xinCoin
                                    "
                                >
                                    {{
                                        TruncateToDecimals2(
                                            selectedData.item.marketData.price
                                                .xinCoin,
                                        )
                                    }}
                                    <img
                                        src="@/assets/images/logo/xin.png"
                                        alt=""
                                    />
                                </span>
                            </div>
                            <div v-if="selectedData.item.marketData.price.USDT">
                                {{
                                    TruncateToDecimals2(
                                        selectedData.item.marketData.price.USDT,
                                    )
                                }}
                                <img
                                    src="@/assets/images/logo/usdt.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div
                            class="lifetime"
                            v-if="selectedData.item.itemData.nodeExpired"
                        >
                            {{ $t('expired') }}
                            :
                            <span>{{
                                new Date(
                                    selectedData.item.itemData.nodeExpired,
                                ).toLocaleString()
                            }}</span>
                        </div>
                        <div
                            class="mining"
                            v-if="selectedData.item.itemData.nodePerformance"
                        >
                            {{ $t('miningCapacity') }}:
                            <span
                                >{{
                                    selectedData.item.itemData.nodePerformance
                                }}
                                Hz/h</span
                            >
                        </div>
                        <div
                            class="nft-number"
                            v-if="selectedData.item.itemData.nftNumber"
                        >
                            {{ $t('number') }}:
                            <span>{{
                                selectedData.item.itemData.nftNumber
                            }}</span>
                        </div>
                        <div
                            class="nft-percentage"
                            v-if="selectedData.item.itemData.percentageOfHz"
                        >
                            {{ $t('percentage') }}:
                            <span
                                >{{
                                    selectedData.item.itemData.percentageOfHz
                                }}
                                %</span
                            >
                        </div>
                        <div class="time">
                            {{ $t('time') }}:
                            <span>{{
                                new Date(
                                    selectedData.item.updatedAt,
                                ).toLocaleString()
                            }}</span>
                        </div>
                        <div class="currency">
                            {{ $t('currency') }}:
                            <span
                                v-if="
                                    selectedData.item.marketData.currency ===
                                    'xinCoin'
                                "
                                >XIN
                                <img src="@/assets/images/logo/xin.png" alt=""
                            /></span>
                            <span
                                v-else-if="
                                    selectedData.item.marketData.currency ===
                                    'USDT'
                                "
                                >USDT
                                <img src="@/assets/images/logo/usdt.png" alt=""
                            /></span>
                        </div>
                        <div class="status">
                            {{ $t('status') }}:
                            <span
                                class="pending"
                                v-if="
                                    selectedData.item.marketData.status ===
                                    'AVAILABLE'
                                "
                            >
                                {{ $t('available') }}
                            </span>
                            <span
                                class="success"
                                v-if="
                                    selectedData.item.marketData.status ===
                                    'SOLD'
                                "
                            >
                                {{ $t('sold') }}
                            </span>
                            <span
                                v-b-tooltip.hover
                                :title="
                                    selectedData.item.marketData.status ===
                                    'CANCELLED'
                                        ? $t('canceled')
                                        : $t('canceledByAdmin')
                                "
                                class="cancel"
                                v-if="
                                    selectedData.item.marketData.status ===
                                        'CANCELLED' ||
                                    selectedData.item.marketData.status ===
                                        'CANCELLED_BY_ADMIN'
                                "
                            >
                                {{ $t('cancel') }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                class="close-modal"
                @click="$bvModal.hide('detail-modal')"
            >
                <path
                    fill="currentColor"
                    d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                />
            </svg>
        </b-modal>
        <PopupConfirm
            @update:open="isPopupCancelSellOpen = $event"
            :open="isPopupCancelSellOpen"
            :onClickConfirm="hanldeCancelSellItem"
            :title="$t('cancelSellItem')"
            :submitButtonTitle="$t('confirm')"
            :description="$t('areYouSureWantToCancelSellTheProduct')"
            :identifier="identifier"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Pagination from '@/components/marketplace/share/Pagination.vue';
import EmptyData from '@/components/marketplace/share/EmptyData.vue';
import {
    sortItemBy,
    COMMIT_CHANGE_QUERY_MYMARKET_PRODUCTS,
    GETTTER_PARAMS_QUERY_MYMARKET_PRODUCT,
    DISPATCH_GET_MYMARKET_PRODUCTS,
} from '../../../../components/marketplace/const';
import FilterComponent from '../../../../components/marketplace/share/FilterComponents.vue';
import MorePopOver from '../../../../components/marketplace/myMarket/MorePopOver.vue';
import PopupConfirm from '../../../../components/marketplace/myMarket/PopupConfirm.vue';

export default {
    components: {
        Pagination,
        EmptyData,
        FilterComponent,
        MorePopOver,
        PopupConfirm,
    },
    data() {
        return {
            fields: [
                {
                    key: 'thumbnail',
                    class: 'text-center',
                    label: 'thumbnail',
                    tdClass: 'align-middle',
                },
                {
                    key: 'name',
                    class: 'text-center',
                    label: 'name',
                    tdClass: 'align-middle',
                },
                {
                    key: 'categories',
                    class: 'text-center',
                    label: 'categories',
                    tdClass: 'align-middle',
                },
                // {
                //     key: 'description',
                // class: 'text-center',
                //     label: 'Description',
                // tdClass: 'align-middle'
                // },
                {
                    key: 'price',
                    class: 'text-center',
                    label: 'price',
                    tdClass: 'align-middle',
                },
                {
                    key: 'currency',
                    class: 'text-center',
                    label: 'currency',
                    tdClass: 'align-middle',
                },
                {
                    key: 'date',
                    class: 'text-center',
                    label: 'time',
                    tdClass: 'align-middle',
                },
                {
                    key: 'status',
                    class: 'text-center position-relative',
                    label: 'status',
                    tdClass: 'align-middle',
                },
                {
                    key: 'action',
                    class: 'text-center',
                    label: 'action',
                    tdClass: 'align-middle',
                },
            ],
            selected: 'desc',
            selectedData: null,
            options: sortItemBy,
            page: 1,
            limit: 10,
            myValue: null,
            dispatchGetList: DISPATCH_GET_MYMARKET_PRODUCTS,
            mapGettersParams: GETTTER_PARAMS_QUERY_MYMARKET_PRODUCT,
            commitParams: COMMIT_CHANGE_QUERY_MYMARKET_PRODUCTS,
            isPopupCancelSellOpen: false,
            idItemCancel: null,
            identifier: 'cancel'
        };
    },

    computed: {
        ...mapGetters({
            ItemToSell: 'marketplace/ItemToSell',
            SoldItemList: 'marketplace/SoldItemList',
            UserInfo: 'info/UserInfo',
        }),
    },

    methods: {
        detailModal(input) {
            this.selectedData = input;
            this.$bvModal.show('detail-modal');
        },

        async handlePrevPage() {
            await this.$store.dispatch('marketplace/getSoldItem', {
                sortBy: this.selected,
                page: Number(this.SoldItemList.page) - 1,
                limit: this.limit,
            });
        },

        async handleNextPage() {
            await this.$store.dispatch('marketplace/getSoldItem', {
                sortBy: this.selected,
                page: Number(this.SoldItemList.page) + 1,
                limit: this.limit,
            });
        },

        async sortItem(e) {
            await this.$store.dispatch('marketplace/getSoldItem', {
                sortBy: e,
                page: this.page,
                limit: this.limit,
            });
        },

        onChangePage(payload) {
            this.page = payload;
            this.reqItemToSold();
        },

        reqItemToSold() {
            return this.$store.dispatch('marketplace/getSoldItem', {
                page: this.page,
                limit: this.limit,
            });
        },

        getPrice(input) {
            if (input.currency === 'xinCoin') {
                return input.price.xinCoin;
            }
            return input.price.USDT;
        },

        closeModal() {
            this.$emit('update:open', false);
        },

        openModalPayment(product) {
            this.infoProductPayment = product;
            this.showModalPayment = true;
        },

        onPopupCancelSellOpen() {
            this.isPopupCancelSellOpen = true;
        },

        openConfirm(data) {
            this.$bvModal.show(`modal-confirm-${this.identifier}`);
            this.idItemCancel = data.marketItemOid;
        },

        async hanldeCancelSellItem() {
            const res = await this.$store.dispatch(
                'marketplace/delCancelSellItem',
                this.idItemCancel,
            );
            if (res.status) {
                this.$store.dispatch('marketplace/getSoldItem', {
                    page: this.page,
                    limit: this.limit,
                });
                this.isPopupCancelSellOpen = false
            }
        },
    },

    emits: ['update:open'],

    mounted() {
        this.$store.dispatch('marketplace/getSoldItem', {
            page: this.page,
            limit: this.limit,
        });
        this.$store.dispatch('marketplace/getSellerList');
    },
};
</script>

<style lang="scss" scoped>
.product-container {
    .product-list {
        padding: 32px;
        border-radius: 20px;
        background: #fff;
        border: 1px solid #d8d8d8;

        .header {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            h3 {
                font-weight: 700;
            }
            .inputs {
                display: flex;
                align-items: center;
                gap: 15px;
                .search,
                .sort {
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    input {
                        border-radius: 8px;
                        color: #8e8e93;
                        font-weight: 600;
                        background: #fff;
                    }

                    select {
                        width: 120px;
                        border-radius: 8px;
                        color: #8e8e93;
                        font-weight: 600;
                        background: #fff
                            url('../../../../assets/images/icons/icon-down.svg')
                            no-repeat 90px 10px;
                    }
                }

                .add-product {
                    width: 140px;
                    border-radius: 8px;
                    font-size: 16px;
                    border: 2px solid #0087cc;
                    padding: 12px 18px;
                    color: #0087cc;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 7px;
                    cursor: pointer;
                    line-height: 24px;
                }
            }
        }

        .table {
            margin-top: 1rem;

            .success,
            .pending,
            .cancel {
                width: 110px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                gap: 7px;
                padding: 2px 10px;
                justify-content: center;
                margin: auto;

                &:before {
                    content: '';
                    display: block;
                    width: 7px;
                    height: 7px;
                    border-radius: 100%;
                }
            }

            .success {
                background: #ecfdf3;
                border: 1px solid #abefc6;
                color: #067647;

                &:before {
                    background: #17b26a;
                }
            }

            .pending {
                background: #cdeeff;
                border: 1px solid #0087cc;
                color: #0087cc;

                &:before {
                    background: #0087cc;
                }
            }

            .cancel {
                background: #fdecec;
                border: 1px solid #efabab;
                color: #d51111;

                &:before {
                    background: #d51111;
                }
            }

            .detail {
                cursor: pointer;
                font-weight: 700;
                text-decoration: underline;
                color: #0087cc;
            }
            .thumbnail {
                img {
                    width: 40px;
                    height: 40px;
                    object-fit: contain;
                }
            }
            .currency {
                color: #0087cc;
                display: flex;
                gap: 6px;
                img {
                    width: 24px;
                }
            }
        }

        @media (max-width: 768px) {
            .header {
                h3 {
                    font-size: 20px;
                }

                .inputs {
                    .add-product {
                        width: 70px;
                        padding: 6px 9px;
                    }
                }
            }
        }

        @media (max-width: 414px) {
            .header {
                h3 {
                    font-size: 16px;
                }

                .inputs {
                    .add-product {
                        width: 50px;
                        padding: 6px 9px;
                    }
                }
            }
        }
    }
}

.pagination {
    display: flex;
    justify-content: end;
}
</style>

<style lang="scss">
#detail-modal {
    padding-right: 0 !important;
    .modal-dialog {
        max-width: 1000px !important;

        @media (max-width: 991px) {
            padding: 0 15px;
        }

        .modal-body {
            border-radius: 22px;
            > div {
                display: flex;
                align-items: center;
                gap: 2rem;

                .bg {
                    width: 400px;
                    height: 400px;
                    background-image: url('../../../../assets/images/background/bg.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .img-detail {
                        width: 80%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .right {
                    flex-grow: 1;

                    .info {
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        .right {
                            .top {
                                font-size: 14px;
                            }

                            .bottom {
                                font-size: 20px;
                                font-weight: 600;
                                color: #3c3c3c;
                            }
                        }
                    }

                    .title {
                        font-size: 32px;
                        color: #0087cc;
                        font-weight: 700;
                    }

                    .price {
                        margin-top: 16px;
                        border-bottom: 1px solid #d8d8d8;
                        padding-bottom: 10px;
                        font-weight: 600;
                        img {
                            width: 24px;
                        }

                        div:nth-child(1) {
                            font-size: 24px;
                            display: flex;
                            justify-content: space-between;

                            span {
                                font-size: 32px;
                                display: flex;
                                align-items: center;
                                gap: 6px;
                            }
                        }

                        div:nth-child(2) {
                            font-size: 24px;
                            text-align: right;
                        }
                    }

                    .lifetime,
                    .mining,
                    .time,
                    .currency,
                    .status,
                    .nft-number,
                    .nft-percentage {
                        display: flex;
                        justify-content: space-between;
                        font-size: 16px;
                        margin-top: 14px;
                        font-weight: 600;
                    }
                    .currency {
                        img {
                            width: 24px;
                        }
                    }

                    .mining,
                    .nft-percentage {
                        padding-bottom: 10px;
                        border-bottom: 1px solid #d8d8d8;
                    }

                    .lifetime,
                    .mining,
                    .nft-number,
                    .nft-percentage {
                        span {
                            color: #0087cc;
                        }
                    }

                    .currency,
                    .nft-number,
                    .nft-percentage {
                        span {
                            color: #0087cc;
                            display: flex;
                            align-items: center;
                            gap: 6px;
                        }
                    }

                    .status {
                        .success,
                        .pending,
                        .cancel {
                            width: 110px;
                            border-radius: 20px;
                            display: flex;
                            align-items: center;
                            gap: 7px;
                            padding: 2px 10px;

                            &:before {
                                content: '';
                                display: block;
                                width: 6px;
                                height: 6px;
                                border-radius: 100%;
                            }
                        }

                        .success {
                            background: #ecfdf3;
                            border: 1px solid #abefc6;
                            color: #067647;

                            &:before {
                                background: #17b26a;
                            }
                        }

                        .pending {
                            background: #cdeeff;
                            border: 1px solid #0087cc;
                            color: #0087cc;

                            &:before {
                                background: #0087cc;
                            }
                        }

                        .cancel {
                            background: #fdecec;
                            border: 1px solid #efabab;
                            color: #d51111;

                            &:before {
                                background: #d51111;
                            }
                        }
                    }
                }

                @media (max-width: 991px) {
                    .left {
                        display: none;
                    }

                    .right {
                        width: 100%;

                        .info {
                            .right {
                                .bottom {
                                    font-size: 16px;
                                }
                            }
                        }

                        .title {
                            font-size: 24px;
                        }

                        .detail-info {
                            .price {
                                div:nth-child(1) {
                                    font-size: 18px;

                                    span {
                                        font-size: 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .close-modal {
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;
                transition: 0.2s;
                font-size: 24px;
                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }
}
</style>
