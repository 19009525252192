var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-container"},[_c('div',{staticClass:"product-list mt-4"},[_c('div',{staticClass:"header"},[_c('h3',[_vm._v(_vm._s(_vm.$t('productList')))]),_c('div',{staticClass:"inputs"},[_c('router-link',{staticClass:"add-product",attrs:{"to":{ name: 'AddProduct' }}},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M4.16406 9.99935H15.8307M9.9974 4.16602V15.8327","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])],1)]),_c('FilterComponent',{attrs:{"dispatchGetList":_vm.dispatchGetList,"commitParams":_vm.commitParams,"getterParams":_vm.mapGettersParams}}),(
                _vm.SoldItemList &&
                _vm.SoldItemList.results &&
                _vm.SoldItemList.results.length > 0
            )?_c('div',{staticClass:"table"},[_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","items":_vm.SoldItemList.results,"fields":_vm.fields},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('h4',{staticClass:"text-center my-3"},[_vm._v("No data")])]},proxy:true},{key:"head()",fn:function(ref){
            var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(name)",fn:function(data){return [_c('strong',[_vm._v(" "+_vm._s(data.item.itemData.name)+" ")])]}},{key:"cell(thumbnail)",fn:function(data){return [_c('div',{staticClass:"thumbnail"},[_c('img',{attrs:{"src":data.item.itemData.photoUrl,"alt":""}})])]}},{key:"cell(currency)",fn:function(data){return [(data.item.marketData.currency === 'xinCoin')?_c('div',[_vm._v(" XIN ")]):_vm._e(),(data.item.marketData.currency === 'USDT')?_c('div',[_vm._v(" USDT ")]):_vm._e()]}},{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(new Date(data.item.updatedAt).toLocaleDateString())+" ")]}},{key:"cell(price)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getPrice(data.item.marketData))+" ")]}},{key:"cell(status)",fn:function(data){return [(data.item.marketData.status === 'AVAILABLE')?_c('div',{staticClass:"pending"},[_vm._v(" "+_vm._s(_vm.$t('available'))+" ")]):_vm._e(),(data.item.marketData.status === 'SOLD')?_c('div',{staticClass:"success"},[_vm._v(" "+_vm._s(_vm.$t('sold'))+" ")]):_vm._e(),(
                            data.item.marketData.status === 'CANCELLED' ||
                            data.item.marketData.status ===
                                'CANCELLED_BY_ADMIN'
                        )?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cancel",attrs:{"title":data.item.marketData.status === 'CANCELLED'
                                ? _vm.$t('canceled')
                                : _vm.$t('canceledByAdmin')}},[_vm._v(" "+_vm._s(_vm.$t('canceled'))+" ")]):_vm._e()]}},{key:"cell(categories)",fn:function(data){return [(data.item.itemData.nodeId)?_c('div',[_vm._v("XNode")]):(data.item.itemData.nftNumber)?_c('div',[_vm._v(" X-NFT ")]):_c('div',[_vm._v("Voucher")])]}},{key:"cell(action)",fn:function(data){return [_c('MorePopOver',{attrs:{"data":data.item,"openDetailModal":function () { return _vm.detailModal(data); },"openConfirmCancelSell":function () { return _vm.openConfirm(data.item); }}})]}}],null,false,2173149741)}),(_vm.SoldItemList.totalPages > 1)?_c('div',{staticClass:"pagination"},[_c('Pagination',{attrs:{"page":_vm.SoldItemList.page,"totalPages":_vm.SoldItemList.totalPages,"handlePrevPage":_vm.handlePrevPage,"handleNextPage":_vm.handleNextPage}})],1):_vm._e()],1):_vm._e(),_c('EmptyData',{attrs:{"list":_vm.SoldItemList.results}})],1),_c('b-modal',{attrs:{"id":"detail-modal","hide-header":"","hide-footer":"","centered":""}},[(_vm.selectedData)?_c('div',[_c('div',{class:['left', { bg: _vm.selectedData.item.itemData.nodeId }]},[_c('img',{staticClass:"img-detail",attrs:{"src":_vm.selectedData.item.itemData.photoUrl,"alt":"img-detail"}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"right"},[_c('div',{staticClass:"top"},[_vm._v(" "+_vm._s(_vm.selectedData.item.userId)+" ")]),(_vm.UserInfo)?_c('div',{staticClass:"bottom"},[_vm._v(" "+_vm._s(_vm.UserInfo.email)+" ")]):_vm._e()])]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.selectedData.item.itemData.name)+" "),(_vm.selectedData.item.itemData.nftNumber)?_c('span',[_vm._v("- "+_vm._s(_vm.selectedData.item.itemData.nftNumber))]):_vm._e(),(_vm.selectedData.item.itemData.nodePerformance)?_c('span',[_vm._v(" - "+_vm._s(_vm.selectedData.item.itemData.nodePerformance)+" HZ ")]):_vm._e()]),_c('div',{staticClass:"detail-info"},[_c('div',{staticClass:"price"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('price'))+": "),(
                                    _vm.selectedData.item.marketData.price
                                        .xinCoin
                                )?_c('span',[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2( _vm.selectedData.item.marketData.price .xinCoin ))+" "),_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"alt":""}})]):_vm._e()]),(_vm.selectedData.item.marketData.price.USDT)?_c('div',[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2( _vm.selectedData.item.marketData.price.USDT ))+" "),_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"alt":""}})]):_vm._e()]),(_vm.selectedData.item.itemData.nodeExpired)?_c('div',{staticClass:"lifetime"},[_vm._v(" "+_vm._s(_vm.$t('expired'))+" : "),_c('span',[_vm._v(_vm._s(new Date( _vm.selectedData.item.itemData.nodeExpired ).toLocaleString()))])]):_vm._e(),(_vm.selectedData.item.itemData.nodePerformance)?_c('div',{staticClass:"mining"},[_vm._v(" "+_vm._s(_vm.$t('miningCapacity'))+": "),_c('span',[_vm._v(_vm._s(_vm.selectedData.item.itemData.nodePerformance)+" Hz/h")])]):_vm._e(),(_vm.selectedData.item.itemData.nftNumber)?_c('div',{staticClass:"nft-number"},[_vm._v(" "+_vm._s(_vm.$t('number'))+": "),_c('span',[_vm._v(_vm._s(_vm.selectedData.item.itemData.nftNumber))])]):_vm._e(),(_vm.selectedData.item.itemData.percentageOfHz)?_c('div',{staticClass:"nft-percentage"},[_vm._v(" "+_vm._s(_vm.$t('percentage'))+": "),_c('span',[_vm._v(_vm._s(_vm.selectedData.item.itemData.percentageOfHz)+" %")])]):_vm._e(),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.$t('time'))+": "),_c('span',[_vm._v(_vm._s(new Date( _vm.selectedData.item.updatedAt ).toLocaleString()))])]),_c('div',{staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.$t('currency'))+": "),(
                                _vm.selectedData.item.marketData.currency ===
                                'xinCoin'
                            )?_c('span',[_vm._v("XIN "),_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"alt":""}})]):(
                                _vm.selectedData.item.marketData.currency ===
                                'USDT'
                            )?_c('span',[_vm._v("USDT "),_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"alt":""}})]):_vm._e()]),_c('div',{staticClass:"status"},[_vm._v(" "+_vm._s(_vm.$t('status'))+": "),(
                                _vm.selectedData.item.marketData.status ===
                                'AVAILABLE'
                            )?_c('span',{staticClass:"pending"},[_vm._v(" "+_vm._s(_vm.$t('available'))+" ")]):_vm._e(),(
                                _vm.selectedData.item.marketData.status ===
                                'SOLD'
                            )?_c('span',{staticClass:"success"},[_vm._v(" "+_vm._s(_vm.$t('sold'))+" ")]):_vm._e(),(
                                _vm.selectedData.item.marketData.status ===
                                    'CANCELLED' ||
                                _vm.selectedData.item.marketData.status ===
                                    'CANCELLED_BY_ADMIN'
                            )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cancel",attrs:{"title":_vm.selectedData.item.marketData.status ===
                                'CANCELLED'
                                    ? _vm.$t('canceled')
                                    : _vm.$t('canceledByAdmin')}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]):_vm._e()])])])]):_vm._e(),_c('svg',{staticClass:"close-modal",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"1em","height":"1em","viewBox":"0 0 24 24"},on:{"click":function($event){return _vm.$bvModal.hide('detail-modal')}}},[_c('path',{attrs:{"fill":"currentColor","d":"M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"}})])]),_c('PopupConfirm',{attrs:{"open":_vm.isPopupCancelSellOpen,"onClickConfirm":_vm.hanldeCancelSellItem,"title":_vm.$t('cancelSellItem'),"submitButtonTitle":_vm.$t('confirm'),"description":_vm.$t('areYouSureWantToCancelSellTheProduct'),"identifier":_vm.identifier},on:{"update:open":function($event){_vm.isPopupCancelSellOpen = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }