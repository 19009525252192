<template>
    <div class="purchase-list">
        <div class="purchase-container">
            <div class="header-purchase">
                <span class="title-purchase">{{
                    this.$t('purchasedList')
                }}</span>
                <div class="filter">
                    <div class="input-search">
                        <div class="search">
                            <input
                                @input="debouncedInput"
                                type="text"
                                :placeholder="this.$t('search')"
                                v-model="searchValue"
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                class="magnifying"
                            >
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m21 21l-4.343-4.343m0 0A8 8 0 1 0 5.343 5.343a8 8 0 0 0 11.314 11.314"
                                />
                            </svg>
                        </div>

                        <div class="sort-category">
                            <b-form-select
                                v-model="selectedCategory"
                                :options="optionsCategory"
                            >
                                <b-form-select-option
                                    :value="null"
                                    disabled
                                    hidden
                                    class="option-category"
                                    style="font-size: 10px"
                                    >{{ $t('category') }}</b-form-select-option
                                >
                            </b-form-select>
                        </div>

                        <b-form-datepicker
                            :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            }"
                            :date-formatter="{
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                            }"
                            :placeholder="$t('startTime')"
                            v-model="fromDate"
                            class="bg-white custom-select-day"
                            tabindex="2"
                        ></b-form-datepicker>

                        <b-form-datepicker
                            :date-format-options="{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            }"
                            :placeholder="$t('endTime')"
                            v-model="toDate"
                            class="bg-white custom-select-day"
                        ></b-form-datepicker>
                    </div>
                    <div v-if="PurchaseList.results.length > 0" class="export">
                        <button
                            @click="handleExport"
                            v-b-tooltip.hover
                            :title="$t('export')"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <g fill="currentColor">
                                    <path
                                        d="M20 15.25a.75.75 0 0 1 .75.75v1A3.75 3.75 0 0 1 17 20.75H7A3.75 3.75 0 0 1 3.25 17v-.996a.75.75 0 1 1 1.5 0V17A2.25 2.25 0 0 0 7 19.25h10A2.25 2.25 0 0 0 19.25 17v-1a.75.75 0 0 1 .75-.75"
                                    />
                                    <path
                                        d="M12.75 4.5a.75.75 0 0 0-1.5 0v6.97H7.97a.75.75 0 0 0 0 1.06l3.5 3.5a.75.75 0 0 0 1.06 0l3.5-3.5a.75.75 0 0 0 0-1.06h-3.28z"
                                    />
                                </g>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <b-table
                v-if="dataTableConverse && dataTableConverse.length > 0"
                :items="dataTableConverse"
                :fields="fields"
                class="purchase-table"
                tbody-class="custom-body"
                thead-class="custom-header"
                responsive
            >
                <template #head()="{ label }">
                    {{ $t(label) }}
                </template>
                <template #cell()="{ value }">
                    <div>{{ value }}</div>
                </template>
                <template #empty>
                    <h4 class="text-center my-3">No Data</h4>
                </template>
                <template #cell(name)="data">
                    <div class="item-custom">
                        {{ data.item.name }}
                    </div>
                </template>
                <template #cell(time)="data">
                    <div class="item-custom">
                        {{ getDateTime2(data.item.time) }}
                    </div>
                </template>
                <template #cell(price)="data">
                    <div v-if="data.item.feeXin || data.item.feeUsdt">
                        <div v-if="data.item.feeXin" class="item-custom">
                            {{
                                TruncateToDecimals2(
                                    data.item.price + data.item.feeXin,
                                )
                            }}
                        </div>
                        <div v-if="data.item.feeUsdt">
                            {{
                                TruncateToDecimals2(
                                    data.item.price + data.item.feeUsdt,
                                )
                            }}
                        </div>
                    </div>
                    <div v-else>
                        {{ TruncateToDecimals2(data.item.price) }}
                    </div>
                </template>
                <template #cell(category)="data">
                    <div class="category item-custom">
                        {{
                            data.item.category === 'nft'
                                ? 'XNFT'
                                : data.item.category === 'node'
                                ? 'Node'
                                : 'Voucher'
                        }}
                    </div>
                </template>
                <template #cell(status)="data">
                    <div class="item-custom">
                        <div :class="renderClassStatus(data.item.status)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 256 256"
                            >
                                <path
                                    fill="currentColor"
                                    d="M156 128a28 28 0 1 1-28-28a28 28 0 0 1 28 28"
                                />
                            </svg>
                            {{
                                data.item.status === 0
                                    ? $t('success')
                                    : data.item.status === 1
                                    ? $t('processing')
                                    : $t('failed')
                            }}
                        </div>
                    </div>
                </template>
                <template #cell(currency)="data">
                    <div class="item-custom">
                        <div
                            v-if="data.item.currency === 'xinCoin'"
                            class="logo"
                        >
                            <img
                                src="@/assets/images/logo/xin.png"
                                alt="xin-logo"
                                class="logo"
                            />
                        </div>
                        <div v-if="data.item.currency === 'USDT'" class="logo">
                            <img
                                src="@/assets/images/logo/usdt.png"
                                alt="usdt-logo"
                                class="logo"
                            />
                        </div>
                    </div>
                </template>
                <template #cell(show_details)="data">
                    <div class="item-custom">
                        <ModalDetail :item="data.item" />
                    </div>
                </template>
            </b-table>

            <EmptyData :list="dataTableConverse" />

            <div
                class="pagination"
                v-if="PurchaseList && PurchaseList.totalPages > 1"
            >
                <Pagination
                    :page="PurchaseList.page"
                    :totalPages="PurchaseList.totalPages"
                    :handlePrevPage="handlePrevPage"
                    :handleNextPage="handleNextPage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';
import moment from 'moment';
import ModalDetail from '../../../components/marketplace/marketplacePurchase/ModalDetail.vue';
import Pagination from '../../../components/marketplace/share/Pagination.vue';
import EmptyData from '../../../components/marketplace/share/EmptyData.vue';

export default {
    components: {
        ModalDetail,
        Pagination,
        EmptyData,
    },

    data() {
        return {
            fromDate: null,
            toDate: null,
            searchValue: null,
            selectedCategory: null,
            currentPage: 1,
            perPage: 10,
            fields: [
                {
                    key: 'name',
                    label: 'name',
                    class: 'text-center row-item-custom',
                    sortable: true,
                },
                {
                    key: 'category',
                    label: 'category',
                    class: 'text-center row-item-custom',
                },
                {
                    key: 'time',
                    label: 'time',
                    class: 'text-center row-item-custom',
                    sortable: true,
                },
                {
                    key: 'price',
                    label: 'amount',
                    class: 'text-center row-item-custom',
                    sortable: true,
                },
                {
                    key: 'currency',
                    label: 'currency',
                    class: 'text-center row-item-custom',
                },
                {
                    key: 'status',
                    label: 'status',
                    class: 'text-center row-item-custom',
                },
                {
                    key: 'show_details',
                    label: 'action',
                    class: 'text-center row-item-custom',
                },
            ],
            options: [
                { value: 'createdAt', text: 'time' },
                { value: 'price', text: 'amount' },
            ],
        };
    },

    methods: {
        renderClassStatus(state) {
            if (state === 0) {
                return 'success';
            }

            if (state === 1) {
                return 'pending';
            }

            return 'cancel';
        },

        handleInput(value) {
            if (value === '') {
                this.$store
                    .dispatch('marketplace/req_getListPurchase', {
                        limit: this.perPage,
                        page: this.currentPage,
                        itemGroup: this.selectedCategory,
                        from: this.fromDate,
                        to: this.toDate,
                    })
                    .then(() => {
                        this.searchValue = null;
                    });
            } else {
                this.$store.dispatch('marketplace/req_getListPurchase', {
                    limit: this.perPage,
                    page: this.currentPage,
                    itemName: value,
                    itemGroup: this.selectedCategory,
                    from: this.fromDate,
                    to: this.toDate,
                });
            }
        },

        handleExport() {
            this.$store.dispatch('marketplace/req_getListPurchase', {
                limit: this.perPage,
                page: this.currentPage,
                itemName: this.searchValue,
                itemGroup: this.selectedCategory,
                from: this.fromDate,
                to: this.toDate,
                isExport: true,
            });
        },

        handleNextPage() {
            this.$store.dispatch('marketplace/req_getListPurchase', {
                limit: this.perPage,
                page: this.PurchaseList.page + 1,
                itemName: this.searchValue,
                itemGroup: this.selectedCategory,
                from: this.fromDate,
                to: this.toDate,
            });
        },

        handlePrevPage() {
            this.$store.dispatch('marketplace/req_getListPurchase', {
                limit: this.perPage,
                page: this.PurchaseList.page - 1,
                itemName: this.searchValue,
                itemGroup: this.selectedCategory,
                from: this.fromDate,
                to: this.toDate,
            });
        },
    },

    computed: {
        ...mapGetters({
            PurchaseList: 'marketplace/PurchaseList',
        }),

        optionsCategory() {
            return [
                { value: '', text: `${this.$t('all')}` },
                { value: 'nft', text: 'NFT' },
                { value: 'node', text: 'Node' },
                // { value: 'voucher', text: 'Voucher' },
            ];
        },

        dataTableConverse() {
            const arr = [];

            if (this.PurchaseList.results.length > 0) {
                this.PurchaseList.results.forEach((data) => {
                    const [currency, price] = Object.entries(data.price)[0];
                    const res = {
                        id: '',
                        name: '',
                        category: '',
                        time: '',
                        price: null,
                        priceXinCoin: null,
                        priceUsdt: null,
                        currency: '',
                        status: 0,
                        image: '',
                        id_user: '',
                        email: '',
                        lifeTime: '',
                        mining: '',
                        percentHz: 0,
                        numberNft: '',
                        feeXin: 0,
                        feeUsdt: 0,
                    };

                    res.id = data._id;
                    res.name = data.itemData?.name;
                    res.category = data.marketData?.itemGroup;
                    res.time = data.createdAt;
                    res.priceXinCoin = data.marketData.price?.xinCoin;
                    res.priceUsdt = data.marketData.price?.USDT;
                    res.status = data.status;
                    res.price = price;
                    res.currency = currency;
                    res.image = data.itemData?.photoUrl;
                    res.mining = data.itemData?.nodePerformance;
                    res.id_user = data.marketData.userData.refId;
                    res.email = data.marketData.userData.email;
                    res.percentHz = data.itemData.percentageOfHz;
                    res.numberNft = data.itemData.nftNumber;
                    res.feeXin = data.fee?.xinCoin ?? null;
                    res.feeUsdt = data.fee?.USDT ?? null;

                    const nodeExpired = data.itemData?.nodeExpired;

                    if (nodeExpired) {
                        let time = null;

                        const expiredDate = moment(nodeExpired);
                        const currentDate = moment();

                        if (currentDate.isAfter(expiredDate)) {
                            time = `0 Days`;
                        } else {
                            const diffInDays = expiredDate.diff(
                                currentDate,
                                'days',
                            );

                            const months = Math.floor(diffInDays / 30);
                            const days = diffInDays % 30;

                            time = `${months} ${this.$t(
                                'months',
                            )} ${days} ${this.$t('days')}`;

                            res.lifeTime = time;
                        }
                    }

                    arr.push(res);
                });

                return arr;
            }
            return arr;
        },

        totalRows() {
            return this.PurchaseList.totalResults;
        },
    },

    watch: {
        currentPage(newPage) {
            this.$store.dispatch('marketplace/req_getListPurchase', {
                limit: this.perPage,
                page: newPage,
                itemName: this.searchValue,
                itemGroup: this.selectedCategory,
                from: this.fromDate,
                to: this.toDate,
            });
        },

        selectedCategory(newCategory) {
            this.$store.dispatch('marketplace/req_getListPurchase', {
                limit: this.perPage,
                page: this.currentPage,
                itemName: this.searchValue,
                itemGroup: newCategory === '' ? null : newCategory,
                from: this.fromDate,
                to: this.toDate,
            });
        },

        fromDate(newFrom) {
            if (this.toDate) {
                this.$store.dispatch('marketplace/req_getListPurchase', {
                    limit: this.perPage,
                    page: this.currentPage,
                    itemName: this.searchValue,
                    itemGroup: this.selectedCategory,
                    from: newFrom,
                    to: this.toDate,
                });
            }
        },

        toDate(newTo) {
            if (this.fromDate) {
                this.$store.dispatch('marketplace/req_getListPurchase', {
                    limit: this.perPage,
                    page: this.currentPage,
                    itemName: this.searchValue,
                    itemGroup: this.selectedCategory,
                    from: this.fromDate,
                    to: newTo,
                });
            }
        },
    },

    created() {
        this.debouncedInput = debounce((event) => {
            this.handleInput(event.target.value);
        }, 700);
    },

    mounted() {
        this.$store.dispatch('marketplace/req_getListPurchase', {
            limit: this.perPage,
            page: this.currentPage,
        });
    },
};
</script>

<style lang="scss" scoped>
.purchase-list {
    padding: 32px;

    .purchase-container {
        border: 1px solid #d8d8d8;
        border-radius: 20px;
        background: white;
        padding: 32px;

        .header-purchase {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding-bottom: 16px;
            position: relative;
            z-index: 1;

            .title-purchase {
                font-size: 24px;
                font-weight: 700;
            }

            .filter {
                display: flex;
                justify-content: space-between;
                gap: 12px;

                .input-search {
                    display: grid;
                    gap: 16px;
                    align-items: center;
                    grid-template-columns: repeat(4, 1fr);

                    .search {
                        height: 40px;
                        position: relative;

                        input {
                            width: 100%;
                            height: 100%;
                            padding: 12px;
                            padding-left: 36px;
                            border: 1px solid #d8d8d8;
                            border-radius: 8px;

                            &:focus-visible {
                                outline: none;
                            }

                            &::placeholder {
                                font-size: 14px;
                                color: #667085;
                            }
                        }

                        .magnifying {
                            position: absolute;
                            left: 3%;
                            top: 50%;
                            transform: translateY(-50%);
                            color: #667085;
                        }
                    }

                    .sort-category {
                        border-radius: 8px;
                        height: 40px;
                        border: 1px solid #aaaaaa6b;

                        .custom-select {
                            border: none;
                            height: 100%;
                            border-radius: 8px;
                            font-size: 14px;
                            color: #667085;

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }

                    @media (max-width: 768px) {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    @media (max-width: 450px) {
                        grid-template-columns: repeat(1, 1fr);
                    }
                }
            }

            @media (max-width: 1280px) {
                gap: 16px;
                flex-direction: column;
            }
        }

        .export {
            display: flex;
            justify-content: end;

            button {
                border: 1px solid #107fb9;
                border-radius: 8px;
                color: #107fb9;
                transition: all 0.2s ease-in-out;
                padding: 2px 6px;

                &:hover {
                    background-color: #107fb9;
                    color: white;
                    transform: scale(0.98);
                }

                &:focus {
                    outline: none;
                }
            }
        }

        .pagination {
            display: flex;
            justify-content: end;
            margin-top: 3px;
        }

        .pagination-purchase {
            .page-item {
                span {
                    border: none;
                }

                button {
                    border: none;
                }
            }
        }
    }

    @media (max-width: 640px) {
        padding: 16px;

        .purchase-container {
            padding: 16px;
        }
    }
}
</style>

<style lang="scss">
.custom-select-day.form-control {
    align-items: center;
    border-radius: 8px;
    height: 40px;
    font-size: 14px;
    color: #667085;
    align-items: center;

    .dropdown-menu {
        width: 100%;
    }
}

.purchase-table {
    margin-bottom: 0;

    .custom-body {
        .row-item-custom {
            border-top: none;
        }

        .item-custom {
            height: 34px;
            align-items: center;
            display: flex;
            justify-content: center;
            // width: 140px;
            margin: auto;

            .logo {
                width: 24px;
                height: 24px;
            }

            .success {
                display: flex;
                align-items: center;
                width: 150px;
                border-radius: 9999px;
                border: 1px solid #abefc6;
                background: #ecfdf3;
                color: #067647;
                font-weight: 500;
                width: fit-content;
                padding: 0 15px 0 0;
            }

            .pending {
                display: flex;
                align-items: center;
                width: 150px;
                border-radius: 9999px;
                border: 1px solid #efd8ab;
                background: #fdf8ec;
                color: #d4a60f;
                font-weight: 500;
                width: fit-content;
                padding: 0 15px 0 0;
            }

            .cancel {
                display: flex;
                align-items: center;
                width: 150px;
                border-radius: 9999px;
                border: 1px solid #efabab;
                background: #fdecec;
                color: #d51111;
                font-weight: 500;
                width: fit-content;
                padding: 0 15px 0 0;
            }
        }
    }

    .custom-header {
        .row-item-custom {
            min-width: 150px;
            border-top: 0;
            border-bottom: 1px solid #3c3c3c;
        }
    }
}
</style>
