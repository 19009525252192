<template>
  <div class="gift-box">
    <IconBox />

    <div v-if="gift && node" class="gift-info">
      <p class="bonus-text">🎁 Bonus</p>
      <img :src="gift.photoUrl" :alt="gift.name" class="gift-image" />
      <p class="gift-name">{{ gift.name }}</p>
      <p class="gift-quantity">x{{ node.giftNodeQuantity }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import IconBox from './IconBox.vue'

export default {
  name: "GiftBox",
  components: { IconBox },
  props: {
    gift: Object,
    node: Object
  },
};
</script>

<style lang="scss" scoped>
.gift-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: shake 1.5s infinite ease-in-out;
  z-index: 200;
}

.gift-info {
  position: absolute;
  top: 70px;
  right: 0;
  background: rgba(255, 255, 255, 0.95);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  width: 130px;
  border: 2px solid #ff9800;

  opacity: 0;
  visibility: hidden;
  transform: translateY(-5px);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.gift-box:hover .gift-info {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.bonus-text {
  font-size: 14px;
  font-weight: bold;
  color: #ff9800;
  margin-bottom: 5px;
}

.gift-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 5px;
}

.gift-name {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 3px;
  color: #333;
}

.gift-quantity {
  font-size: 12px;
  color: #888;
}

@keyframes shake {
  0%,
  100% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(-5deg);
  }

  50% {
    transform: rotate(5deg);
  }

  75% {
    transform: rotate(-3deg);
  }
}
</style>
