<template>
    <div class="lastest-container">
        <div class="title">{{ $t('lastestProduct') }}</div>
        <div
            class="carousel"
            v-if="lastestProduct && lastestProduct.length > 0"
        >
            <div class="tab">
                <div
                    class="node-tab"
                    :class="{ active: activeTab === 'node' }"
                    @click="handleChangeTab('node')"
                >
                    Node
                </div>
                <div>/</div>
                <div
                    class="nft-tab"
                    :class="{ active: activeTab === 'nft' }"
                    @click="handleChangeTab('nft')"
                >
                    NFT
                </div>
            </div>
            <VueSlickCarousel v-bind="settingSlick" :key="carouselKey">
                <div
                    class="item"
                    v-for="(item, index) in lastestProduct"
                    :key="`${item._id}-${index}`"
                    @mousedown="startDrag"
                    @mouseup="endDrag(item, $event)"
                >
                    <div class="img">
                        <img :src="item.itemData.photoUrl" alt="" />
                    </div>
                    <div class="overlay">
                        <div class="name">{{ item.itemData.name }}</div>
                        <div class="price">
                            Seller: #{{ item.userData.refId }}
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <div class="carousel no-data" v-else>{{ $t('noData') }}</div>

        <!-- <ModalPayment
            :open="showModalPayment"
            :product="selectedProduct"
            @update:open="showModalPayment = $event"
        /> -->
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
    components: {
        VueSlickCarousel,
    },

    data() {
        return {
            products: undefined,
            itemGroupValue: null,
            selectedProduct: null,
            showModalPayment: false,
            startX: 0,
            startY: 0,
            activeTab: 'node',
            settingSlick: {
                dots: true,
                infinite: true,
                speed: 800,
                slidesToShow: 3,
                // slidesToScroll: 4,
                // initialSlide: 0,
                autoplay: true,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true,
                            dots: true,
                        },
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            initialSlide: 2,
                        },
                    },
                ],
            },
            carouselKey: 0,
        };
    },

    computed: {
        lastestProduct() {
            if (this.products?.results && this.products.results.length > 0) {
                return this.products.results.slice(0, 6);
            }
            return [];
        },
    },

    methods: {
        handleChangeTab(input) {
            this.activeTab = input;
            this.$store
                .dispatch('marketplaceNft/myGetProducts', {
                    itemGroup: input,
                })
                .then((res) => {
                    if (res) {
                        this.products = res.data;
                        this.carouselKey += 1;
                    }
                });
        },

        startDrag(event) {
            this.startX = event.clientX;
            this.startY = event.clientY;
        },
        endDrag(item, event) {
            const endX = event.clientX;
            const endY = event.clientY;
            const distance = Math.sqrt(
                Math.pow(endX - this.startX, 2) +
                    Math.pow(endY - this.startY, 2),
            );

            console.log('distance', distance);
            if (distance < 5) {
                this.openModalPayment(item);
            }
        },

        openModalPayment(product) {
            this.$store.commit('marketplace/SET_PRODUCT', product);
            this.$bvModal.show('payment-modal');
        },
    },

    mounted() {
        // this.$store.dispatch('marketplaceNft/myGetProducts').then((res) => {
        //     if (res) {
        //         this.products = res.data;
        //     }
        // });

        this.handleChangeTab('node');
    },
};
</script>

<style lang="scss" scoped>
.lastest-container {
    .title {
        font-size: 40px;
        font-weight: 600;
        color: #0087cc;
    }

    .select-wrapper {
        display: flex;
        justify-content: flex-end;
        select {
            width: 150px;
            height: 40px;
        }
    }

    #category {
        padding: 10px 16px;
        border: 1px solid #aaaaaa6b;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 600;
        color: #8e8e93;
        background: url('../icons/arrowDownIcon.png') no-repeat right;
        background-size: 18px;
        background-position-x: 125px;
        z-index: 20;
        text-transform: uppercase;
    }

    .carousel {
        padding: 30px 10px;
        margin: 60px 0;
        border: 2px solid #00d1ff;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        position: relative;
        background: #fff;

        .tab {
            display: flex;
            position: absolute;
            gap: 10px;
            bottom: 100%;
            left: -2px;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            border: 2px solid #00d1ff;
            border-bottom: 0;
            padding: 0 15px;
            font-size: 24px;
            background: #fff;
            z-index: 2;

            .node-tab,
            .nft-tab {
                cursor: pointer;
            }

            .active {
                color: #0087cc;
                font-weight: bold;
            }
        }

        .item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url('../../../assets/images/background/bg.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: 22px;
            cursor: pointer;
            height: 270px;
            // margin: 0 10px;

            .img {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                transition: transform 0.2s;

                img {
                    width: 85%;
                }
            }

            .overlay {
                display: none;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 22px;
                transition: 0.2s;
                .name,
                .price {
                    color: #ffff;
                }

                .name {
                    font-weight: 600;
                    font-size: 18px;
                }

                .price {
                    font-weight: 400;
                    font-size: 14px;
                }
            }

            &:hover {
                .img {
                    transform: scale(1.1);
                }

                .overlay {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
            }
        }
    }

    .no-data {
        font-size: 30px;
        text-align: center;
        font-weight: 600;
    }

    .active {
        color: #0087cc;
    }

    @media (max-width: 991px) {
        .title {
            font-size: 30px;
        }
    }
}
</style>

<style lang="scss">
.lastest-container {
    .carousel {
        .VueCarousel {
            .VueCarousel-inner {
                gap: 20px;
            }
        }

        .slick-slide {
            > div {
                margin: 0 10px;

                .item {
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .slick-dots {
            li {
                .slick-active {
                    button {
                        &::before {
                            color: #0087cc !important;
                        }
                    }
                }
            }
        }
    }
}
</style>
