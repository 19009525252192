<template>
  <span v-if="typeDiscount === '%'">
    <span v-if="
      selectedVoucher &&
      selectedVoucher.voucherSetting &&
      currency === 'XIN'
    ">
      {{ chosenNode.name }} -
      {{ discountWithVoucher(chosenNode.priceXin).toLocaleString('en-US') }}
      {{ currency }}
    </span>
    <span v-else-if="
      selectedVoucher &&
      selectedVoucher.voucherSetting &&
      currency === 'USDT'
    ">
      {{ chosenNode.name }} -
      {{ discountWithVoucher(chosenNode.priceUSDT).toLocaleString('en-US') }}
      {{ currency }}
    </span>
    <span v-else-if="
      selectedVoucher &&
      selectedVoucher.voucherSetting &&
      currency === '50-50'
    ">
      {{ chosenNode.name }} -
      <span>
        {{
          discountWithVoucher(chosenNode.priceXin / 2).toLocaleString(
            'en-US',
          )
        }}
        XIN
      </span>
      {{ $t('and') }}
      <span>
        {{
          discountWithVoucher(chosenNode.priceUSDT / 2).toLocaleString(
            'en-US',
          )
        }}
        USDT
      </span>
    </span>
    <span v-else-if="currency === 'XIN'">
      {{ chosenNode.name }} -
      {{ discountPrice(chosenNode.priceXin).toLocaleString('en-US') }}
      {{ currency }}
    </span>
    <span v-else-if="currency === 'xinCoin'">
      {{ chosenNode.name }} -
      {{ discountPrice(chosenNode.priceXin).toLocaleString('en-US') }}
      {{ 'XIN' }}
    </span>
    <span v-else-if="currency === '50-50'">
      {{ chosenNode.name }} -
      <span>
        {{ discountPrice(chosenNode.priceXin / 2).toLocaleString('en-US') }}
        XIN
      </span>
      and
      <span>
        {{
          discountPrice(chosenNode.priceUSDT / 2).toLocaleString('en-US')
        }}
        USDT
      </span>
    </span>
    <span v-else-if="currency === 'USDT' && typeDiscount === 'usdt'">
      {{ chosenNode.name }} -
      {{ discountPrice(chosenNode.priceUSDT).toLocaleString('en-US') }}
      {{ currency }}
    </span>
    <span v-else class="price">
      {{ chosenNode.name }} -
      {{ discountPrice(chosenNode.priceUSDT).toLocaleString('en-US') }}
      {{ currency }}
    </span>
  </span>
  <span v-else>
    <span v-if="
      selectedVoucher &&
      selectedVoucher.voucherSetting &&
      currency === 'XIN'
    ">
      {{ chosenNode.name }} -
      {{ chosenNode.priceXin.toLocaleString('en-US') }}
      {{ currency }}
    </span>
    <span v-else-if="
      selectedVoucher &&
      selectedVoucher.voucherSetting &&
      currency === 'USDT'
    ">
      {{ chosenNode.name }} -
      {{ discountWithVoucher(chosenNode.priceUSDT).toLocaleString('en-US') }}
      {{ currency }}
    </span>
    <span v-else-if="
      selectedVoucher &&
      selectedVoucher.voucherSetting &&
      currency === '50-50'
    ">
      {{ chosenNode.name }} -
      <span>
        {{
          (chosenNode.priceXin / 2).toLocaleString(
            'en-US',
          )
        }}
        XIN
      </span>
      {{ $t('and') }}
      <span>
        {{
          discountWithVoucher(chosenNode.priceUSDT / 2).toLocaleString(
            'en-US',
          )
        }}
        USDT
      </span>
    </span>
    <span v-else-if="currency === 'XIN'">
      {{ chosenNode.name }} -
      {{ chosenNode.priceXin.toLocaleString('en-US') }}
      {{ currency  }}
    </span>
    <span v-else-if="currency === 'xinCoin'">
      {{ chosenNode.name }} -
      {{ chosenNode.priceXin.toLocaleString('en-US') }}
      XIN
    </span>
    <span v-else-if="currency === '50-50'">
      {{ chosenNode.name }} -
      <span>
        {{ (chosenNode.priceXin / 2).toLocaleString('en-US') }}
        XIN
      </span>
      and
      <span>
        {{
          discountPrice(chosenNode.priceUSDT / 2).toLocaleString('en-US')
        }}
        USDT
      </span>
    </span>
    <span v-else class="price">
      {{ chosenNode.name }} -
      {{ discountPrice(chosenNode.priceUSDT).toLocaleString('en-US') }}
      {{ currency }}
    </span>
  </span>

</template>

<script lang="ts">
export default {
    props: {
        selectedVoucher: Object,
        currency: String,
        chosenNode: Object,
        discountValue: Number,
        typeDiscount: String,
    },

    methods: {
        discountPrice(price) {
        if (this.typeDiscount === '%')
            return price * (1 - this.discountValue / 100);
        return price - this.discountValue;
        },
        discountWithVoucher(price) {
            if (this.typeDiscount === '%') {
                const totalDiscount =
                    this.selectedVoucher.voucherSetting.amount * 100 +
                    this.discountValue;
                return price * (1 - totalDiscount / 100);
            }

            return (
                price -
                (this.selectedVoucher.voucherSetting.amount +
                    this.discountValue)
            );
        },
    },
};
</script>
