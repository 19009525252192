<template>
    <div class="container py-4">
        <BannerPromotion :promotions="NFTPromo.results" />
        <div class="listing">
            <div class="heading-container">
                <router-link :to="{ name: 'BuyNFT' }" class="head">
                    X-NFT
                </router-link>
                <router-link :to="{ name: 'LocalEsim' }" class="head">
                    eSIM
                </router-link>
            </div>
            <router-view />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BannerPromotion from '../../components/promotion/BannerPromotion.vue';

export default {
  components: { BannerPromotion },
    computed: {
        ...mapGetters({
            NFTPromo: 'promotion/NFTPromo',
        }),
    },
};
</script>

<style lang="scss" scoped>
.tit {
    font-size: 20px;
    color: #0087cc;
    font-weight: 600;
}

.listing {
    padding: 10px 0px;
    margin-top: 15px;

    .heading-container {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .head {
        color: #0087cc;
        font-weight: 600;
        margin-bottom: 15px;
        border: 1px solid #0087cc;
        padding: 8px 16px;
        border-radius: 8px;
        cursor: pointer;
    }

    .head.active {
        background-color: #0087cc;
        color: #ffffff;
    }
}
</style>
