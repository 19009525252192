<template>
  <div class="promotion-box">
    <div class="promo-content">
      <span class="promo-power arrow-up">
        <span class="arrow-up"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g transform="rotate(-90 12 12)">
              <path fill="currentColor" d="M15.5 5H11l5 7l-5 7h4.5l5-7z" />
              <path fill="currentColor" d="M8.5 5H4l5 7l-5 7h4.5l5-7z" />
            </g>
          </svg>
        </span>
        {{ promoPercentage.toLocaleString('en-US') }} Hz/h</span>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "PromotionHZ",
  props: {
    basePower: {
      type: Number,
      required: true
    },
    promoPercentage: {
      type: Number,
      required: true
    }
  },
  computed: {
    boostedPower() {
      return Math.floor(this.basePower + this.promoPercentage);
    }
  }
};
</script>

<style lang="scss" scoped>
.promotion-box {
  position: absolute;
  z-index: 200;
  top: -10px;
  right: -5px;
  // background: #0a2e0a;
  padding: 12px;
  border-radius: 12px;
  // border: 2px solid #00ff64;
  // box-shadow: 0 4px 12px rgba(0, 255, 100, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.ribbon {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  background: #00ff64;
  color: #0a2e0a;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 255, 100, 0.4);
  text-transform: uppercase;
}

.promo-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.promo-power {
  font-size: 24px;
  font-weight: bold;
  color: #00ff64;
  text-shadow: 0 2px 4px rgba(0, 255, 100, 0.5);
  margin-top: 5px;
}

.arrow-up {
  font-size: 20px;
  font-weight: bold;
  color: #00ff64;
  animation: bounceArrow 1s infinite alternate ease-in-out;
  transform: rotate(-45 deg);
}

@keyframes bounceArrow {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-5px);
  }
}
</style>
