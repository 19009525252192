<template>
    <div>
        <div class="popup">
            <MoreIcon :id="`data-${data._id}`" />
            <b-popover
                custom-class="context-popover"
                :target="`data-${data._id}`"
                placement="bottom"
                triggers="click blur"
            >
                <div class="detail" @click="openDetailModal(data)">
                    <ViewIcon /> <span>{{ $t('detail') }}</span>
                </div>
                <div
                    v-if="data.marketData.status === 'AVAILABLE'"
                    class="stop-sell-btn"
                    @click="openConfirmCancelSell"
                >
                    <StopIcon /> <span>{{ $t('cancelSell') }}</span>
                </div>
            </b-popover>
        </div>
    </div>
</template>
<script>
import MoreIcon from '../icons/MoreIcon.vue';
import ViewIcon from '../icons/ViewIcon.vue';
import StopIcon from '../icons/StopIcon.vue';

export default {
    components: { MoreIcon, ViewIcon, StopIcon },
    props: {
        data: Object,
        openDetailModal: Function,
        openConfirmCancelSell: Function,
    },
};
</script>
<style lang="scss" scoped>
.popup {
    cursor: pointer;
    margin-left: 25px;
}

.popover-body {
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.detail,
.stop-sell-btn {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    padding: 3px;
    line-height: 24px;
    font-weight: 500;
}
.stop-sell-btn {
    border-top: 1px solid #ddd;
    color: #d51111;
}
</style>

<style lang="scss">
.context-popover {
    .popover-body {
        padding: 0px;
        overflow: hidden;
        > div {
            padding: 4px 16px;
            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
}
</style>
