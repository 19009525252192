var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history-container"},[_c('div',{staticClass:"history-list mt-4"},[_c('div',{staticClass:"header"},[_c('h3',[_vm._v(_vm._s(_vm.$t('historyInformation')))]),(_vm.SoldItemList.results.length > 0)?_c('div',[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"export",attrs:{"title":_vm.$t('export')},on:{"click":_vm.handleExport}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","viewBox":"0 0 24 24"}},[_c('g',{attrs:{"fill":"currentColor"}},[_c('path',{attrs:{"d":"M20 15.25a.75.75 0 0 1 .75.75v1A3.75 3.75 0 0 1 17 20.75H7A3.75 3.75 0 0 1 3.25 17v-.996a.75.75 0 1 1 1.5 0V17A2.25 2.25 0 0 0 7 19.25h10A2.25 2.25 0 0 0 19.25 17v-1a.75.75 0 0 1 .75-.75"}}),_c('path',{attrs:{"d":"M12.75 4.5a.75.75 0 0 0-1.5 0v6.97H7.97a.75.75 0 0 0 0 1.06l3.5 3.5a.75.75 0 0 0 1.06 0l3.5-3.5a.75.75 0 0 0 0-1.06h-3.28z"}})])])])]):_vm._e()]),_c('FilterComponent',{attrs:{"dispatchGetList":_vm.dispatchGetList,"commitParams":_vm.commitParams,"getterParams":_vm.mapGettersParams}}),(_vm.SoldItemList)?_c('div',{staticClass:"table"},[_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","items":_vm.SoldItemList.results,"fields":_vm.fields},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('h4',[_vm._v("No data")])]},proxy:true},{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm.$t(label))+" ")]}},{key:"cell(name)",fn:function(data){return [_c('strong',[_vm._v(" "+_vm._s(data.item.itemData.name)+" ")])]}},{key:"cell(thumbnail)",fn:function(data){return [_c('div',{staticClass:"thumbnail"},[_c('img',{attrs:{"src":data.item.itemData.photoUrl,"alt":""}})])]}},{key:"cell(currency)",fn:function(data){return [(data.item.marketData.currency === 'xinCoin')?_c('div',[_vm._v(" XIN ")]):_vm._e(),(data.item.marketData.currency === 'USDT')?_c('div',[_vm._v(" USDT ")]):_vm._e()]}},{key:"cell(date)",fn:function(data){return [(data.item.action.createdAt)?_c('div',[_vm._v(" "+_vm._s(new Date( data.item.action.createdAt ).toLocaleDateString())+" ")]):_vm._e()]}},{key:"cell(price)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getPrice(data.item.marketData))+" ")]}},{key:"cell(status)",fn:function(data){return [(data.item.marketData.status === 'AVAILABLE')?_c('div',{staticClass:"pending"},[_vm._v(" "+_vm._s(_vm.$t('available'))+" ")]):_vm._e(),(data.item.marketData.status === 'SOLD')?_c('div',{staticClass:"success"},[_vm._v(" "+_vm._s(_vm.$t('sold'))+" ")]):_vm._e(),(
                            data.item.marketData.status === 'CANCELLED' ||
                            data.item.marketData.status ===
                                'CANCELLED_BY_ADMIN'
                        )?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cancel",attrs:{"title":data.item.marketData.status === 'CANCELLED'
                                ? _vm.$t('canceled')
                                : _vm.$t('canceledByAdmin')}},[_vm._v(" "+_vm._s(_vm.$t('canceled'))+" ")]):_vm._e()]}},{key:"cell(categories)",fn:function(data){return [(data.item.itemData.nodeId)?_c('div',[_vm._v("XNode")]):(data.item.itemData.nftId)?_c('div',[_vm._v("X-NFT")]):_c('div',[_vm._v("Voucher")])]}},{key:"cell(action)",fn:function(data){return [(data.item.action.userId)?_c('div',{staticClass:"detail-history",on:{"click":function($event){return _vm.detailModal(data)}}},[_vm._v(" "+_vm._s(_vm.$t('detail'))+" ")]):_vm._e()]}}],null,false,2786545569)}),(_vm.SoldItemList.totalPages > 1)?_c('div',{staticClass:"pagination"},[_c('Pagination',{attrs:{"page":_vm.SoldItemList.page,"totalPages":_vm.SoldItemList.totalPages,"handlePrevPage":_vm.handlePrevPage,"handleNextPage":_vm.handleNextPage}})],1):_vm._e()],1):_vm._e(),_c('EmptyData',{attrs:{"list":_vm.SoldItemList.results}})],1),_c('b-modal',{attrs:{"id":"history-detail-modal","hide-header":"","hide-footer":"","centered":""}},[(_vm.selectedData)?_c('div',[_c('div',{class:['left', { bg: _vm.selectedData.item.itemData.nodeId }]},[_c('img',{staticClass:"img-detail",attrs:{"src":_vm.selectedData.item.itemData.photoUrl,"alt":""}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.selectedData.item.itemData.name)+" "),(_vm.selectedData.item.itemData.nftNumber)?_c('span',[_vm._v("- "+_vm._s(_vm.selectedData.item.itemData.nftNumber))]):_vm._e(),(_vm.selectedData.item.itemData.nodePerformance)?_c('span',[_vm._v(" - "+_vm._s(_vm.selectedData.item.itemData.nodePerformance)+" HZ ")]):_vm._e()]),_c('div',{staticClass:"detail-info"},[_c('div',{staticClass:"price"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('price'))+": "),(
                                    _vm.selectedData.item.marketData.price
                                        .xinCoin
                                )?_c('span',[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2( _vm.selectedData.item.marketData.price .xinCoin ))+" "),_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"alt":""}})]):_vm._e()]),(_vm.selectedData.item.marketData.price.USDT)?_c('div',[_vm._v(" "+_vm._s(_vm.TruncateToDecimals2( _vm.selectedData.item.marketData.price.USDT ))+" "),_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"alt":""}})]):_vm._e()]),(_vm.selectedData.item.itemData.nodeExpired)?_c('div',{staticClass:"lifetime"},[_vm._v(" "+_vm._s(_vm.$t('expired'))+": "),_c('span',[_vm._v(_vm._s(new Date( _vm.selectedData.item.itemData.nodeExpired ).toLocaleString()))])]):_vm._e(),(_vm.selectedData.item.itemData.nodePerformance)?_c('div',{staticClass:"mining"},[_vm._v(" "+_vm._s(_vm.$t('miningCapacity'))+": "),_c('span',[_vm._v(_vm._s(_vm.selectedData.item.itemData.nodePerformance)+" Hz/h ")])]):_vm._e(),(_vm.selectedData.item.itemData.nftNumber)?_c('div',{staticClass:"nft-number"},[_vm._v(" "+_vm._s(_vm.$t('number'))+": "),_c('span',[_vm._v(_vm._s(_vm.selectedData.item.itemData.nftNumber))])]):_vm._e(),(_vm.selectedData.item.itemData.percentageOfHz)?_c('div',{staticClass:"nft-percentage"},[_vm._v(" "+_vm._s(_vm.$t('percentage'))+": "),_c('span',[_vm._v(_vm._s(_vm.selectedData.item.itemData.percentageOfHz)+" %")])]):_vm._e(),_c('div',{staticClass:"buyer-info"},[_vm._v(" "+_vm._s(_vm.$t('customInfo'))+" "),_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.selectedData.item.action.userData.refId)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.selectedData.item.action.userData.email)+" ")])])]),_c('div',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.$t('time'))+": "),_c('span',[_vm._v(_vm._s(new Date( _vm.selectedData.item.updatedAt ).toLocaleString()))])]),_c('div',{staticClass:"currency"},[_vm._v(" "+_vm._s(_vm.$t('currency'))+": "),(
                                _vm.selectedData.item.marketData.currency ===
                                'xinCoin'
                            )?_c('span',[_vm._v("XIN "),_c('img',{attrs:{"src":require("@/assets/images/logo/xin.png"),"alt":""}})]):(
                                _vm.selectedData.item.marketData.currency ===
                                'USDT'
                            )?_c('span',[_vm._v("USDT "),_c('img',{attrs:{"src":require("@/assets/images/logo/usdt.png"),"alt":""}})]):_vm._e()])])])]):_vm._e(),_c('svg',{staticClass:"close-modal",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"1em","height":"1em","viewBox":"0 0 24 24"},on:{"click":function($event){return _vm.$bvModal.hide('history-detail-modal')}}},[_c('path',{attrs:{"fill":"currentColor","d":"M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }