<template>
  <div class="discount-badge" v-if="shouldShowDiscountBadge">
    <p class="discount-title">Giảm giá:</p>

    <div class="price">
      <div class="price-info">
        <template v-if="specialPrice && specialPrice.length >= 2 && currency !== 'usdt'">
          <span class="original-price">
            {{ (specialPrice[0] / 2).toLocaleString('en-US') }} XIN
            &
            {{ (specialPrice[1] / 2).toLocaleString('en-US') }} USDT
          </span>
          <span class="final-price">
            {{ typePartXIN.toLocaleString('en-US') }} XIN &
            {{ typePartUSDT.toLocaleString('en-US') }} USDT
          </span>
        </template>

        <template v-else>
          <span class="original-price">
            {{ originalPrice.toLocaleString('en-US') }}
            {{ currency }}</span>
          <span class="final-price">
            {{ finalPrice.toLocaleString('en-US') }}
            {{ currency }}</span>
        </template>
      </div>
      <div class="discount-tag">
        <span v-if="discountType === '%'">-{{ discountValue }}%</span>
        <span v-else>
          <span>
            -{{ discountValue.toLocaleString('en-US') }}
            {{ 'USDT' }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    originalPrice: {
      type: Number,
      required: true,
    },
    discountValue: {
      type: Number,
      required: true,
    },
    discountType: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    specialPrice: {
      type: Array,
    },
    isUSDT: {
      type: Boolean
    }
  },
  data() {
    return {
      typePartXIN: 0,
      typePartUSDT: 0,
    };
  },
  computed: {
    finalPrice() {
      const discountValue = this.discountValue || 0;
      if (this.discountType === '%') {
        return this.originalPrice * (1 - discountValue / 100);
      }
      return this.discountType !== '%'
        ? Math.max(0, this.originalPrice - discountValue)
        : Math.min(discountValue, this.originalPrice - discountValue);
    },

    shouldShowDiscountBadge() {
      console.log(this.currency, this.discountType);
      let result;
      if (this.discountType === '%') {
        result = true;
      } else if (this.discountType === 'usdt') {
        result = this.currency === 'USDT' || this.currency === 'XIN & USDT';
      } else {
        result = false;
      }
      console.log('shouldShowDiscountBadge:', result);
      return result;
    }
  },
  watch: {
    specialPrice: {
      handler(newVal) {
        if (newVal && newVal.length >= 2) {
          if (this.discountType === 'usdt') {
            this.typePartXIN = (newVal[0] / 2);
            this.typePartUSDT = (newVal[1] / 2) - this.discountValue;
          } else {
            this.typePartXIN = (newVal[0] / 2) * (1 - this.discountValue / 100);
            this.typePartUSDT = (newVal[1] / 2) * (1 - this.discountValue / 100);
          }
        } else {
          this.typePartXIN = 0;
          this.typePartUSDT = 0;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    shouldShowUSDTDiscount() {
      return this.discountType === 'usdt';
    }
  },
};
</script>
<style lang="scss" scoped>
.discount-badge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 5px;
  margin-top: 5px;

  .price {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .price-info {
    display: flex;
    flex-direction: column;

    .original-price {
      text-decoration: line-through;
      font-size: 18px;
    }

    .final-price {
      font-size: 18px;
      font-weight: light;
      color: #999;
    }
  }

  .discount-tag {
    background: #bd1550;
    color: white;
    font-weight: light;
    font-size: 16px;
    padding: 4px 6px;
    border-radius: 4px;
  }
}
</style>