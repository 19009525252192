<template>
  <div class="ribbon">
    <span style="display: flex; flex-direction: column;">
      <strong><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
            color="currentColor">
            <path d="M10.996 10h.015M11 16h.015M7 13h8" />
            <circle cx="1.5" cy="1.5" r="1.5" transform="matrix(1 0 0 -1 16 8)" />
            <path
              d="M2.774 11.144c-1.003 1.12-1.024 2.81-.104 4a34 34 0 0 0 6.186 6.186c1.19.92 2.88.899 4-.104a92 92 0 0 0 8.516-8.698a1.95 1.95 0 0 0 .47-1.094c.164-1.796.503-6.97-.902-8.374s-6.578-1.066-8.374-.901a1.95 1.95 0 0 0-1.094.47a92 92 0 0 0-8.698 8.515" />
          </g>
        </svg> {{ discount }} {{ type }}</strong>
    </span>
  </div>
</template>

<script lang="ts">
export default {
    props: {
        discount: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.ribbon {
    font-size: 20px;
    font-weight: normal;
    color: #fff;
    z-index: 200;
    text-transform: uppercase;
}

.ribbon {
    --f: 0.5em;

    position: absolute;
    top: 0;
    right: 0;
    line-height: 1.8;
    padding-inline: 1lh;
    padding-bottom: var(--f);
    border-image: conic-gradient(#0008 0 0) 51% / var(--f);
    clip-path: polygon(
        100% calc(100% - var(--f)),
        100% 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),
        var(--f) calc(100% - var(--f)),
        0 100%,
        0 calc(100% - var(--f)),
        999px calc(100% - var(--f) - 999px),
        calc(100% - 999px) calc(100% - var(--f) - 999px)
    );
    transform: translate(calc((1 - cos(45deg)) * 100%), -100%) rotate(45deg);
    transform-origin: 0% 100%;
    background-color: #bd1550;
}
</style>
