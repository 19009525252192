<template>
    <div class="balance-container mt-4">
        <div class="chart mt-4">
            <div class="wrap" v-if="MarketStatistics">
                <div v-for="(item, index) in dateRevenue" :key="index">
                    <div class="title">
                        <span v-if="item._id === 'sum'">{{
                            $t('totalRevenue')
                        }}</span>
                        <span v-if="item._id === 'node'">{{
                            $t('revenueNode')
                        }}</span>
                        <span v-if="item._id === 'nft'">{{
                            $t('revenueNFT')
                        }}</span>
                    </div>
                    <div class="revenue">
                        <div>
                            {{
                                Number(item.totalXinCoin).toLocaleString(
                                    'en-US',
                                )
                            }}
                            <img
                                src="@/assets/images/logo/xin.png"
                                alt="logo-xin"
                            />
                        </div>
                        <div>
                            {{ Number(item.totalUSDT).toLocaleString('en-US') }}
                            <img
                                src="@/assets/images/logo/usdt.png"
                                alt="logo-usdt"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ChartMix />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChartMix from '../../../../components/marketplace/myMarket/ChartMix.vue';

export default {
    components: {
        ChartMix,
    },

    computed: {
        ...mapGetters({
            MarketStatistics: 'marketplace/MarketStatistics',
        }),

        dateRevenue() {
            return this.MarketStatistics?.slice(0, -1);
        },
    },

    mounted() {
        this.$store.dispatch('marketplace/getMarketStatistics');
    },
};
</script>

<style scoped lang="scss">
.chart {
    .wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-evenly;
        gap: 32px;
        margin: 24px 0;

        > div {
            background: #fff;
            border: 1px solid #d8d8d8;
            border-radius: 20px;
            padding: 32px;
        }

        .title {
            color: #3c3c3c;
            font-weight: 700;
        }

        .revenue {
            margin-top: 4rem;
            text-align: right;
            font-size: 24px;
            font-weight: 600;

            > div {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 7px;
                img {
                    width: 24px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .chart {
        .wrap {
            grid-template-columns: 1fr;

            > div {
                padding: 16px;
            }
        }
    }
}
</style>
