<template>
    <div class="banner-container">
        <div class="banner">
            <img
                src="../../../assets/images/background/marketplace_banner.png"
                alt=""
                class="desktop"
            />
            <img
                src="../../../assets/images/background/marketplace_banner_mb.png"
                alt=""
                class="mobile"
            />
        </div>
        <div
            class="statistical"
            v-if="MarketStatistics && MarketStatistics.length > 0"
        >
            <div class="total-items" v-if="products">
                {{ $t('itemInMarket') }}:
                <span>{{ products.totalResults }}</span>
            </div>

            <div class="total-usdt">
                {{ $t('totalUSDTCirculated') }}:
                <span>{{ MarketStatistics[0].totalUSDT }}</span>
            </div>
            <div class="total-xin">
                {{ $t('totalXINCirculated') }}:
                <span>{{ MarketStatistics[0].totalXinCoin }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            products: null,
            statistical: null,
        };
    },
    computed: {
        ...mapGetters({
            MarketStatistics: 'marketplace/MarketStatistics',
        }),
    },
    mounted() {
        this.$store.dispatch('marketplaceNft/myGetProducts').then((res) => {
            if (res) {
                console.log('my data: ', res.data);
                this.products = res.data;
            }
        });

        this.$store.dispatch('marketplace/getMarketStatistics');
    },
};
</script>

<style lang="scss" scoped>
.banner-container {
    margin: 0 -32px;
    margin-top: -32px;
    margin-bottom: 30px;
    position: relative;

    .banner {
        img {
            width: 100%;
        }

        .mobile {
            display: none;
        }

        @media (max-width: 991px) {
            .desktop {
                display: none;
            }

            .mobile {
                display: block;
            }
        }
    }

    .statistical {
        position: absolute;
        bottom: 20px;
        right: 40px;

        .total-items,
        .total-usdt,
        .total-xin {
            font-size: 24px;
            color: #5c5252;
            font-weight: 600;
            background: #fff;
            border-radius: 12px;
            border: 1px solid #00d1ff;
            padding: 4px 12px;
            margin-bottom: 10px;

            span {
                color: #0087cc;
            }
        }

        .total-items {
            animation: leftRight 5s infinite;
        }

        .total-usdt {
            animation: rightLeft 5s infinite;
        }

        .total-xin {
            animation: leftRight 7s infinite;
        }

        @keyframes leftRight {
            0% {
                transform: translateX(0);
            }
            50% {
                transform: translateX(30px);
            }

            100% {
                transform: translateX(0);
            }
        }

        @keyframes rightLeft {
            0% {
                transform: translateX(0);
            }
            50% {
                transform: translateX(-30px);
            }

            100% {
                transform: translateX(0);
            }
        }

        @media (max-width: 991px) {
            position: static;

            padding: 0 32px;
            margin-top: 32px;
            .total-items,
            .total-usdt,
            .total-xin {
                font-size: 20px;
                animation: none;
            }
        }
    }
}
</style>
