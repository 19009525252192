import axios from 'axios';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import store from '@/store';
// VUE_APP_REST_API_BETA_URL
// VUE_APP_REST_API_URL
Vue.use(VueCookies);

const token = VueCookies.get('xintel_user');

const otpRequiredUrl = [
    'pipay/payment-bill',
    'pipay/payment-bill-manual',
    'voucher/gotit/buy',
];

if (token != null && token.key != null) {
    axios.defaults.headers.common.Accept = 'application/json';
    axios.defaults.headers.common.Authorization = `Bearer ${token.key}`;
}

// axios.defaults.headers.common.Authorization = `Bearer `;
const instance = axios.create({
    baseURL: process.env.VUE_APP_REST_API_URL,
    ContentType: 'application/json',
    Accept: 'application/json',
    common: ['Access-Control-Allow-Origin', '*'],
});

function checkCodeStatus(code) {
    if (code >= 500) {
        return 500;
    }
    if (code >= 400) {
        return 400;
    }

    return false;
}

instance.interceptors.request.use(
    (config) => {
        const newConfig = config;

        const tokenVerify = VueCookies.get('token');

        if (tokenVerify) {
            const checkUrl = otpRequiredUrl.includes(newConfig.url);

            if (checkUrl) {
                newConfig.data = {
                    ...newConfig.data,
                    verificationToken: tokenVerify,
                };
            }
        }

        if (config.url !== 'api/box/open/multi/Mistery') {
            store.commit('onLoad');
        }

        return newConfig;
    },
    (error) => {
        Promise.reject(error);
    },
);

instance.interceptors.response.use(
    (res) => {
        store.commit('outLoad');
        if (res.data.status) {
            if (
                res.data.message &&
                res.data.message.length > 1 &&
                res.data.message !== ''
            ) {
                store.commit('core/SUCCESS_MESSAGE', res.data.message, {
                    root: true,
                });
            }
            // eslint-disable-next-line consistent-return
            return res.data;
        }

        if (
            !res.data.status &&
            res.data.message === '"verificationToken" is required'
        ) {
            store.commit('onToggleModal', false);
            store.commit('onToggleModal', true);
            return true;
        }

        if (!res.data.status && res.data.errCode === '"verificationToken') {
             VueCookies.remove('token');
            store.commit('onToggleModal', false);
            store.commit('onToggleModal', true);
            return true;
        }

        if (
            res.data.status === false &&
            res.data.message &&
            res.data.message.length > 1 &&
            res.data.message !== ''
        ) {
            store.commit('core/ERROR_MESSAGE', res.data.message, {
                root: true,
            });
        }
        // eslint-disable-next-line consistent-return
        return res.data;
    },
    (error) => {
        store.commit('outLoad');
        if (error.response) {
            if (error.response.status === 401) {
                store.commit('auth/LOGOUT_SUCCESS');
                window.location.href = `${window.location.origin}`;
                store.commit('core/ERROR_MESSAGE', 'Session Timeout', {
                    root: true,
                });
                return;
            }

            if (checkCodeStatus(error.response.status) === 500) {
                store.commit('core/ERROR_500', 500, { root: true });
                // return;
            }

            if (error.response.data) {
                const data = error.response.data;
                if (
                    typeof data.message == 'string' &&
                    data.message.length > 0
                ) {
                    store.commit('core/ERROR_MESSAGE', data.message, {
                        root: true,
                    });
                }
            }

            // if (error.response.status === 413) {
            //     store.commit('core/ERROR_MESSAGE', 'Image Too Large', {
            //         root: true,
            //     });
            // }
        }
    },
);

export default instance;
