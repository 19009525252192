<template>
    <div class="product-container">
        <BannerProduct />
        <LastestProduct />
        <FilterComponent
            :dispatchGetList="dispatchStoreGetListItem"
            :commitParams="commitParams"
            :getterParams="mapGettersParams"
            :justifyBetween="true"
            :useSearchPrimaryStyle="true"
        />

        <div class="list-product">
            <CardProduct
                v-for="(product, i) in products"
                :key="i"
                class="product"
                :product="product"
                @detail="openModalDetail(product)"
                @payment="openModalPayment(product)"
            />
        </div>
        <EmptyData :list="products" />
        <div class="pagination" v-if="pagination.totalPages > 1">
            <Pagination
                :page="pagination.page"
                :totalPages="pagination.totalPages"
                :handlePrevPage="handlePrevPage"
                :handleNextPage="handleNextPage"
            />
        </div>

        <!-- Modal -->
        <ModalInfoProduct />
        <ModalPayment />
        <ModalPaymentSucess :open="isBuySuccess" />
    </div>
</template>
<script lang="ts">
import { mapGetters } from 'vuex';
import FilterComponent from '../share/FilterComponents.vue';
import Pagination from '../share/Pagination.vue';
import CardProduct from './CardProduct.vue';
import ModalInfoProduct from './ModalInfoProduct.vue';
import ModalPaymentSucess from './ModalPaymentSucess.vue';
import EmptyData from '../share/EmptyData.vue';
import LastestProduct from './LastestProduct.vue';

import {
    COMMIT_CHANGE_QUERY_MARKET_PRODUCTS,
    DISPATCH_GET_MARKET_PRODUCTS,
    GETTER_PARAMS_QUERY_MARKET_PRODUCTS,
} from '../const';
import ModalPayment from './ModalPayment.vue';
import BannerProduct from './BannerProduct.vue';

export default {
    name: 'MarketPlaceNFT',
    components: {
        FilterComponent,
        CardProduct,
        ModalInfoProduct,
        Pagination,
        ModalPaymentSucess,
        EmptyData,
        LastestProduct,
        ModalPayment,
        BannerProduct,
    },
    data() {
        return {
            showModalPayment: false,
            showModalInfo: false,
            showModalPaymentSuccess: false,
            dispatchStoreGetListItem: DISPATCH_GET_MARKET_PRODUCTS,
            mapGettersParams: GETTER_PARAMS_QUERY_MARKET_PRODUCTS,
            commitParams: COMMIT_CHANGE_QUERY_MARKET_PRODUCTS,
        };
    },
    computed: {
        ...mapGetters({
            products: 'marketplaceNft/Products',
            isBuySuccess: 'marketplaceNft/getIsBuySuccess',
            pagination: 'marketplaceNft/getPagination',
            SelectedProduct: 'marketplace/SelectedProduct',
        }),
    },
    methods: {
        openModalPayment(product) {
            this.$store.commit('marketplace/SET_PRODUCT', product);
            this.$bvModal.show('payment-modal');
        },
        handleModalPaymentUpdate(isOpen) {
            this.showModalPayment = isOpen; // Update the state when the modal is closed
        },
        async openModalDetail(product) {
            this.$bvModal.show('info-modal');
            this.$store.commit('marketplace/SET_PRODUCT', product);
            console.log('find this bitch')
        },
        handlePrevPage() {
            this.$store.commit(COMMIT_CHANGE_QUERY_MARKET_PRODUCTS, {
                page: this.pagination.page - 1,
            });
        },

        handleNextPage() {
            this.$store.commit(COMMIT_CHANGE_QUERY_MARKET_PRODUCTS, {
                page: this.pagination.page + 1,
            });
        },
    },

    mounted() {
        this.$store.dispatch(DISPATCH_GET_MARKET_PRODUCTS);
        // this.$store.dispatch(DISPATCH_GET_FILTER);
    },
};
</script>
<style lang="scss" scoped>
.product-container {
    padding: 32px;

    .pagination {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .list-product {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 15px;
        margin-top: 20px;
    }

    @media (max-width: 1024px) {
        .list-product {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 768px) {
        .list-product {
            grid-template-columns: 1fr;
        }
    }
}
</style>
