var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.typeDiscount === '%')?_c('span',[(
    _vm.selectedVoucher &&
    _vm.selectedVoucher.voucherSetting &&
    _vm.currency === 'XIN'
  )?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "+_vm._s(_vm.discountWithVoucher(_vm.chosenNode.priceXin).toLocaleString('en-US'))+" "+_vm._s(_vm.currency)+" ")]):(
    _vm.selectedVoucher &&
    _vm.selectedVoucher.voucherSetting &&
    _vm.currency === 'USDT'
  )?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "+_vm._s(_vm.discountWithVoucher(_vm.chosenNode.priceUSDT).toLocaleString('en-US'))+" "+_vm._s(_vm.currency)+" ")]):(
    _vm.selectedVoucher &&
    _vm.selectedVoucher.voucherSetting &&
    _vm.currency === '50-50'
  )?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "),_c('span',[_vm._v(" "+_vm._s(_vm.discountWithVoucher(_vm.chosenNode.priceXin / 2).toLocaleString( 'en-US' ))+" XIN ")]),_vm._v(" "+_vm._s(_vm.$t('and'))+" "),_c('span',[_vm._v(" "+_vm._s(_vm.discountWithVoucher(_vm.chosenNode.priceUSDT / 2).toLocaleString( 'en-US' ))+" USDT ")])]):(_vm.currency === 'XIN')?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "+_vm._s(_vm.discountPrice(_vm.chosenNode.priceXin).toLocaleString('en-US'))+" "+_vm._s(_vm.currency)+" ")]):(_vm.currency === 'xinCoin')?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "+_vm._s(_vm.discountPrice(_vm.chosenNode.priceXin).toLocaleString('en-US'))+" "+_vm._s('XIN')+" ")]):(_vm.currency === '50-50')?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "),_c('span',[_vm._v(" "+_vm._s(_vm.discountPrice(_vm.chosenNode.priceXin / 2).toLocaleString('en-US'))+" XIN ")]),_vm._v(" and "),_c('span',[_vm._v(" "+_vm._s(_vm.discountPrice(_vm.chosenNode.priceUSDT / 2).toLocaleString('en-US'))+" USDT ")])]):(_vm.currency === 'USDT' && _vm.typeDiscount === 'usdt')?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "+_vm._s(_vm.discountPrice(_vm.chosenNode.priceUSDT).toLocaleString('en-US'))+" "+_vm._s(_vm.currency)+" ")]):_c('span',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "+_vm._s(_vm.discountPrice(_vm.chosenNode.priceUSDT).toLocaleString('en-US'))+" "+_vm._s(_vm.currency)+" ")])]):_c('span',[(
    _vm.selectedVoucher &&
    _vm.selectedVoucher.voucherSetting &&
    _vm.currency === 'XIN'
  )?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "+_vm._s(_vm.chosenNode.priceXin.toLocaleString('en-US'))+" "+_vm._s(_vm.currency)+" ")]):(
    _vm.selectedVoucher &&
    _vm.selectedVoucher.voucherSetting &&
    _vm.currency === 'USDT'
  )?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "+_vm._s(_vm.discountWithVoucher(_vm.chosenNode.priceUSDT).toLocaleString('en-US'))+" "+_vm._s(_vm.currency)+" ")]):(
    _vm.selectedVoucher &&
    _vm.selectedVoucher.voucherSetting &&
    _vm.currency === '50-50'
  )?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "),_c('span',[_vm._v(" "+_vm._s((_vm.chosenNode.priceXin / 2).toLocaleString( 'en-US' ))+" XIN ")]),_vm._v(" "+_vm._s(_vm.$t('and'))+" "),_c('span',[_vm._v(" "+_vm._s(_vm.discountWithVoucher(_vm.chosenNode.priceUSDT / 2).toLocaleString( 'en-US' ))+" USDT ")])]):(_vm.currency === 'XIN')?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "+_vm._s(_vm.chosenNode.priceXin.toLocaleString('en-US'))+" "+_vm._s(_vm.currency)+" ")]):(_vm.currency === 'xinCoin')?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "+_vm._s(_vm.chosenNode.priceXin.toLocaleString('en-US'))+" XIN ")]):(_vm.currency === '50-50')?_c('span',[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "),_c('span',[_vm._v(" "+_vm._s((_vm.chosenNode.priceXin / 2).toLocaleString('en-US'))+" XIN ")]),_vm._v(" and "),_c('span',[_vm._v(" "+_vm._s(_vm.discountPrice(_vm.chosenNode.priceUSDT / 2).toLocaleString('en-US'))+" USDT ")])]):_c('span',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.chosenNode.name)+" - "+_vm._s(_vm.discountPrice(_vm.chosenNode.priceUSDT).toLocaleString('en-US'))+" "+_vm._s(_vm.currency)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }