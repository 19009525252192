<template>
    <b-modal
        :id="`modal-confirm-${identifier}`"
        class="modal"
        hide-header
        hide-footer
        centered
        body-class="modal-body-content"
    >
        <div class="modal-content">
            <span class="close" @click="closeModal">
                <CloseIcon color="#333" />
            </span>
            <div class="modal-body">
                <h3>{{ title }}</h3>
                <p>
                    {{ description }} -
                    {{ Number(price).toLocaleString('en-US') }} USDT
                </p>
                <div class="buttons">
                    <button @click="closeModal">
                        {{ $t('cancel') }}
                    </button>
                    <button @click="handleClick">
                        {{
                            submitButtonTitle
                                ? $t(submitButtonTitle)
                                : 'confirm'
                        }}
                    </button>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import CloseIcon from '../icons/CloseIcon.vue';

export default {
    name: 'PopupConfirm',
    components: { CloseIcon },
    data() {
        return {};
    },

    emits: ['update:open'],

    props: {
        open: {
            type: Boolean,
            required: true,
        },
        onClickConfirm: {
            type: Function,
        },
        params: {
            type: Object,
        },

        type: {
            type: String,
        },

        title: {
            type: String,
        },

        submitButtonTitle: {
            type: String,
        },

        description: {
            type: String,
        },

        price: {
            type: Number,
        },

        identifier: {
            type: String,
        },
    },

    methods: {
        closeModal() {
            this.$emit('update:open', false);
            this.$bvModal.hide(`modal-confirm-${this.identifier}`);
        },

        async handleClick() {
            await this.onClickConfirm(this.params);
        },
    },
};
</script>
<style lang="scss" scoped>
$modal-bg-color: rgba(0, 0, 0, 0.5);
$white-color: #fff;
$border-color: #ddd;
$text-color: #333;
$secondary-text-color: #999;
$primary-color: #0087cc;
$primary-hover-color: #0056b3;
$error-color: red;
$gap-desktop: 10px;
$gap-mobie: 5px;
$sucess-color: #10cb48;

#modal-confirm {
    border-radius: 22px;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $modal-bg-color;
    z-index: 1000;

    .modal-body {
        border-radius: 20px;
    }

    .modal-content {
        border-radius: 20px;
        h3 {
            color: $primary-color;
            font-size: 26px;
            font-weight: 600;
            text-align: center;
        }

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            text-align: center;
        }

        .close {
            position: absolute;
            z-index: 9;
            top: 5px;
            right: 10px;
            cursor: pointer;
        }

        .modal-body {
            .buttons {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 1.5rem;
                gap: 1rem;

                button {
                    padding: 8px 0;
                    width: 70%;
                    border-radius: 72px;
                    text-transform: uppercase;
                    font-size: 18px;
                    transition: 0.5s;

                    &:hover {
                        opacity: 0.5;
                    }

                    &.pending {
                        filter: grayscale(1);
                    }
                }

                button:nth-child(1) {
                    border: 1px solid #0087cc;
                    color: #0087cc;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 7px;
                    font-weight: 600;
                }

                button:nth-child(2) {
                    background: linear-gradient(to right, #1775b8, #91c4e9);
                    color: #fff;
                    font-weight: 600;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .modal-content {
            max-height: 90%;
            gap: 8px;

            .modal-header {
                gap: $gap-mobie;
                border-bottom: 1px solid $border-color;
                padding-bottom: 5px;
            }
        }
    }
}
</style>
<style lang="scss">
$modal-bg-color: rgba(0, 0, 0, 0.5);
$white-color: #fff;
$border-color: #ddd;
$text-color: #333;
$secondary-text-color: #999;
$primary-color: #0087cc;
$primary-hover-color: #0056b3;
$error-color: red;
$gap-desktop: 10px;
$gap-mobie: 5px;
$sucess-color: #10cb48;
.modal-body-content {
    border-radius: 22px;

    @media (max-width: 768px) {
        .modal-content {
            width: 100%;
            max-height: 90%;
            gap: 8px;
        }
    }
}
</style>
