<template>
    <div class="navigation">
        <div class="logo">
            <img src="@/assets/images/logo/logo-xin.png" />
        </div>
        <div class="links">
            <!-- DASHBOARD -->
            <router-link :to="{ name: 'Dashboard' }">
                <DashboardIcon />
                <span> {{ $t('dashboard') }} </span>
            </router-link>

            <!-- XIN Wallet -->
            <div class="ecosystem">
                <div
                    :class="{ active: walletGroup }"
                    class="icon-ecosystem"
                    @click="toggleScroll($refs.walletRef, 'wallet')"
                >
                    <WalletIcon />
                    <p>{{ $t('xinWallet') }}</p>
                    <ArrowDownIcon
                        :classIcon="`icon-right ${showWallet && 'active'}`"
                    />
                </div>

                <div
                    ref="walletRef"
                    :style="walletStyle"
                    class="ecosystem-list"
                >
                    <!-- WALLET -->
                    <router-link :to="{ name: 'Wallet' }">
                        <span> {{ $t('wallet') }} </span>
                    </router-link>

                    <!-- STAKING -->
                    <router-link :to="{ name: 'StakingView' }">
                        <span>{{ $t('staking') }}</span>
                    </router-link>

                    <!-- P2P Exchange -->
                    <router-link :to="{ name: 'P2PExchange' }">
                        <span>{{ $t('p2pExchange') }}</span>
                    </router-link>

                    <!-- FIN CARE -->
                    <a href="https://fincarebank.io/" target="_blank">
                        <span>{{ $t('fincare') }}</span>
                    </a>
                </div>
            </div>

            <!-- AFFILIATE -->

            <div class="ecosystem">
                <div
                    :class="{ active: AffiliateGroup }"
                    class="icon-ecosystem"
                    @click="toggleScroll($refs.affiliateRef, 'affiliate')"
                >
                    <MemberIcon />

                    <p>{{ $t('affiliate') }}</p>

                    <!-- RIGHT -->
                    <ArrowDownIcon
                        :classIcon="`icon-right ${showAffiliate && 'active'}`"
                    />
                </div>

                <div
                    ref="affiliateRef"
                    :style="affiliateStyle"
                    class="ecosystem-list"
                >
                    <router-link :to="{ name: 'Member' }">
                        <span> {{ $t('affiliate') }} </span>
                    </router-link>
                    <router-link :to="{ name: 'AmbassadorView' }">
                        <span> {{ $t('ambassador') }} </span>
                    </router-link>
                </div>
            </div>

            <!-- XIN XNODE -->
            <div class="ecosystem">
                <div
                    :class="{ active: XNodeGroup }"
                    class="icon-ecosystem"
                    @click="toggleScroll($refs.ecosystemRef, 'ecosystem')"
                >
                    <XNodeIcon />
                    <p>{{ $t('xinXNode') }}</p>

                    <!-- RIGHT -->
                    <ArrowDownIcon
                        :classIcon="`icon-right ${showEcosystem && 'active'}`"
                    />
                </div>
                <div
                    ref="ecosystemRef"
                    :style="ecosystemStyle"
                    class="ecosystem-list"
                >
                    <!-- X NODE -->
                    <router-link :to="{ name: 'BuyXnode' }">
                        <span> {{ $t('xNode') }} </span>
                    </router-link>

                    <!-- ENTERPRISE -->
                    <router-link :to="{ name: 'CorpoPackage' }">
                        <span> {{ $t('enterpriseNode') }} </span>
                    </router-link>

                    <!-- X NFT -->
                    <router-link :to="{ name: 'BuyNFT' }">
                        <span>{{ $t('X-NFT') }}</span>
                    </router-link>

                    <!-- MY X NODE -->
                    <router-link :to="{ name: 'XnodeInventory' }">
                        <span> {{ $t('myXNode') }} </span>
                    </router-link>
                </div>
            </div>

            <!-- XIN TOUR -->
            <div class="ecosystem">
                <div
                    class="icon-ecosystem"
                    @click="toggleScroll($refs.tourRef, 'tour')"
                >
                    <TourIcon />
                    <p>{{ $t('xinTour') }}</p>

                    <!-- RIGHT -->
                    <ArrowDownIcon
                        :classIcon="`icon-right ${showTour && 'active'}`"
                    />
                </div>
                <div ref="tourRef" :style="tourStyle" class="ecosystem-list">
                    <a href="#" @click="$toastr.i($t('comingSoon'))">
                        <span>{{ $t('itinerary') }}</span>
                    </a>

                    <a href="https://travel.xintel.info" target="_blank">
                        <span>{{ $t('tourGuide') }}</span>
                    </a>
                </div>
            </div>

            <!-- XIN SERVICE  -->
            <div class="ecosystem">
                <div
                    :class="{ active: XinServiceGroup }"
                    class="icon-ecosystem"
                    @click="toggleScroll($refs.serviceRef, 'service')"
                >
                    <ServiceIcon />
                    <p>{{ $t('xinService') }}</p>
                    <ArrowDownIcon
                        :classIcon="`icon-right ${showService && 'active'}`"
                    />
                </div>
                <div
                    ref="serviceRef"
                    :style="serviceStyle"
                    class="ecosystem-list"
                >
                    <!-- UTILITY -->

                    <router-link :to="{ name: 'UtilityOptions' }">
                        <span>{{ $t('utility') }}</span>
                    </router-link>

                    <!-- SHOP -->
                    <a href="#" @click="$toastr.i($t('comingSoon'))">
                        <span>{{ $t('shop') }}</span>
                    </a>

                    <!-- VOUCHER -->
                    <router-link :to="{ name: 'Voucher' }">
                        <span>{{ $t('voucher') }}</span>
                    </router-link>

                    <!-- LOCATION -->
                    <router-link :to="{ name: 'MasterNodeMap' }">
                        <span>{{ $t('xinLocation') }}</span>
                    </router-link>
                </div>
            </div>

            <!-- Market Place -->
            <div class="ecosystem">
                <div
                    @click="toggleScroll($refs.marketPlaceRef, 'marketPlace')"
                    class="icon-ecosystem"
                    :class="{ active: MarketPlaceGroup }"
                >
                    <MarketPlaceIcon />
                    <p>{{ $t('marketPlace') }}</p>
                    <ArrowDownIcon
                        :classIcon="`icon-right ${showMarketPlace && 'active'}`"
                    />
                </div>
                <div
                    :style="marketPlaceStyle"
                    ref="marketPlaceRef"
                    class="ecosystem-list"
                >
                    <router-link :to="{ name: 'MarketPlace' }">
                        <span>{{ $t('marketPlace') }}</span>
                    </router-link>
                    <router-link :to="{ name: 'PurchasedProducts' }">
                        <span>{{ $t('purchasedProducts') }}</span>
                    </router-link>
                    <router-link :to="{ name: 'MyMarket' }">
                        <span>{{ $t('myMarket') }}</span>
                    </router-link>
                </div>
            </div>

            <router-link :to="{ name: 'SupportView' }">
                <SupportIcon />
                <span>{{ $t('support') }}</span>
            </router-link>

            <router-link :to="{ name: 'Activity' }">
                <ActivityIcon />

                <span>{{ $t('activity') }}</span>
            </router-link>
        </div>

        <div :class="{ open: showMenu }" class="links-pop">
            <img
                class="menu-logo"
                src="@/assets/images/logo/logo_authen.png"
                width="180px"
            />

            <div id="navigation-mobie-wapper" class="navigation-mobie-wapper">
                <!-- DASHBOARD -->
                <router-link :to="{ name: 'Dashboard' }">
                    <DashboardIcon />
                    <span> {{ $t('dashboard') }} </span>
                </router-link>
                <!-- XIN Wallet -->
                <div class="ecosystem">
                    <div
                        class="icon-ecosystem"
                        @click="toggleScroll($refs.walletRefMB, 'wallet')"
                    >
                        <WalletIcon />
                        <p>{{ $t('xinWallet') }}</p>

                        <!-- RIGHT -->
                        <ArrowDownIcon
                            :classIcon="`icon-right ${showWallet && 'active'}`"
                        />
                    </div>
                    <div
                        ref="walletRefMB"
                        :style="walletStyle"
                        class="ecosystem-list"
                    >
                        <!-- WALLET -->
                        <router-link :to="{ name: 'Wallet' }">
                            <span> {{ $t('wallet') }} </span>
                        </router-link>

                        <!-- STAKING -->
                        <router-link :to="{ name: 'StakingView' }">
                            <span>{{ $t('staking') }}</span>
                        </router-link>

                        <!-- P2P Exchange -->
                        <router-link :to="{ name: 'P2PExchange' }">
                            <span>{{ $t('p2pExchange') }}</span>
                        </router-link>

                        <!-- FIN CARE -->
                        <a href="https://fincarebank.io/" target="_blank">
                            <span>{{ $t('fincare') }}</span>
                        </a>
                    </div>
                </div>

                <!-- AFFILIATE -->

                <div class="ecosystem">
                    <div
                        class="icon-ecosystem"
                        @click="toggleScroll($refs.affiliateRefMB, 'affiliate')"
                    >
                        <MemberIcon />

                        <p>{{ $t('affiliate') }}</p>

                        <!-- RIGHT -->
                        <ArrowDownIcon
                            :classIcon="`icon-right ${
                                showAffiliate && 'active'
                            }`"
                        />
                    </div>

                    <div
                        ref="affiliateRefMB"
                        :style="affiliateStyle"
                        class="ecosystem-list"
                    >
                        <router-link :to="{ name: 'Member' }">
                            <span> {{ $t('affiliate') }} </span>
                        </router-link>
                        <router-link :to="{ name: 'AmbassadorView' }">
                            <span> {{ $t('ambassador') }} </span>
                        </router-link>
                    </div>
                </div>

                <!-- XIN XNODE -->
                <div class="ecosystem">
                    <div
                        class="icon-ecosystem"
                        @click="toggleScroll($refs.ecosystemRefMb, 'ecosystem')"
                    >
                        <XNodeIcon />
                        <p>{{ $t('xinXNode') }}</p>

                        <!-- RIGHT -->
                        <ArrowDownIcon
                            :classIcon="`icon-right ${
                                showEcosystem && 'active'
                            }`"
                        />
                    </div>

                    <div
                        ref="ecosystemRefMb"
                        :style="ecosystemStyle"
                        class="ecosystem-list"
                    >
                        <!-- X NODE -->
                        <router-link :to="{ name: 'BuyXnode' }">
                            <span> {{ $t('xNode') }} </span>
                        </router-link>

                        <!-- ENTERPRISE -->
                        <router-link :to="{ name: 'CorpoPackage' }">
                            <span> {{ $t('enterpriseNode') }} </span>
                        </router-link>

                        <!-- X NFT -->
                        <router-link :to="{ name: 'BuyNFT' }">
                            <span>{{ $t('X-NFT') }}</span>
                        </router-link>

                        <!-- MY X NODE -->
                        <router-link :to="{ name: 'XnodeInventory' }">
                            <span> {{ $t('myXNode') }} </span>
                        </router-link>
                    </div>
                </div>

                <!-- XIN TOUR -->
                <div class="ecosystem">
                    <div
                        class="icon-ecosystem"
                        @click="toggleScroll($refs.tourRefMb, 'tour')"
                    >
                        <TourIcon />
                        <p>{{ $t('xinTour') }}</p>

                        <!-- RIGHT -->
                        <ArrowDownIcon
                            :classIcon="`icon-right ${showTour && 'active'}`"
                        />
                    </div>
                    <div
                        ref="tourRefMb"
                        :style="tourStyle"
                        class="ecosystem-list"
                    >
                        <a href="#" @click="$toastr.i($t('comingSoon'))">
                            <span>{{ $t('itinerary') }}</span>
                        </a>

                        <a href="https://travel.xintel.info" target="_blank">
                            <span>{{ $t('tourGuide') }}</span>
                        </a>
                    </div>
                </div>

                <!-- XIN SERVICE  -->
                <div class="ecosystem">
                    <div
                        class="icon-ecosystem"
                        @click="toggleScroll($refs.serviceRefMb, 'service')"
                    >
                        <ServiceIcon />

                        <p>{{ $t('xinService') }}</p>

                        <!-- RIGHT -->
                        <ArrowDownIcon
                            :classIcon="`icon-right ${showService && 'active'}`"
                        />
                    </div>
                    <div
                        ref="serviceRefMb"
                        :style="serviceStyle"
                        class="ecosystem-list"
                    >
                        <!-- UTILITY -->

                        <router-link :to="{ name: 'Utilities' }">
                            <span>{{ $t('utility') }}</span>
                        </router-link>

                        <!-- SHOP -->
                        <a href="#" @click="$toastr.i($t('comingSoon'))">
                            <span>{{ $t('shop') }}</span>
                        </a>

                        <!-- VOUCHER -->
                        <router-link :to="{ name: 'Voucher' }">
                            <span>{{ $t('voucher') }}</span>
                        </router-link>

                        <!-- LOCATION -->
                        <router-link :to="{ name: 'MasterNodeMap' }">
                            <span>{{ $t('xinLocation') }}</span>
                        </router-link>
                    </div>
                </div>

                <!-- Market Place -->
                <div class="ecosystem">
                    <div
                        @click="
                            toggleScroll($refs.marketPlaceRefMb, 'marketPlace')
                        "
                        class="icon-ecosystem"
                        :class="{ active: MarketPlaceGroup }"
                    >
                        <MarketPlaceIcon />
                        <p>{{ $t('marketPlace') }}</p>
                        <ArrowDownIcon
                            :classIcon="`icon-right ${
                                showMarketPlace && 'active'
                            }`"
                        />
                    </div>
                    <div
                        :style="marketPlaceStyle"
                        ref="marketPlaceRefMb"
                        class="ecosystem-list"
                    >
                        <router-link :to="{ name: 'MarketPlace' }">
                            <span>{{ $t('marketPlace') }}</span>
                        </router-link>
                        <router-link :to="{ name: 'PurchasedProducts' }">
                            <span>{{ $t('purchasedProducts') }}</span>
                        </router-link>
                        <router-link :to="{ name: 'MyMarket' }">
                            <span>{{ $t('myMarket') }}</span>
                        </router-link>
                    </div>
                </div>

                <router-link :to="{ name: 'SupportView' }">
                    <SupportIcon />
                    <span>{{ $t('support') }}</span>
                </router-link>

                <router-link :to="{ name: 'Activity' }">
                    <ActivityIcon />
                    <span>{{ $t('activity') }}</span>
                </router-link>
            </div>

            <button class="btn-close" @click="showMenu = !showMenu">
                <CloseIcon />
            </button>
        </div>
        <button class="logout" @click="logout">
            <img src="@/assets/images/icons/navigation/logout.svg" />
            <span class="d-none d-lg-inline"> {{ $t('logout') }} </span>
        </button>
        <button class="menu-botan" @click="showMenu = !showMenu">
            <ButanIcon />
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { navigations } from '.';
import {
    ActivityIcon,
    ArrowDownIcon,
    ButanIcon,
    CloseIcon,
    DashboardIcon,
    MemberIcon,
    ServiceIcon,
    SupportIcon,
    MarketPlaceIcon,
    TourIcon,
    WalletIcon,
    XNodeIcon,
} from './icons/index';

export default {
    name: 'MyNavigation',
    components: {
        DashboardIcon,
        WalletIcon,
        ArrowDownIcon,
        MemberIcon,
        XNodeIcon,
        TourIcon,
        ServiceIcon,
        SupportIcon,
        ActivityIcon,
        CloseIcon,
        ButanIcon,
        MarketPlaceIcon,
    },
    data() {
        return {
            showMenu: false,

            showEcosystem: false,
            ecosystemStyle: {
                height: '0px',
            },

            showService: false,
            serviceStyle: {
                height: '0px',
            },

            showTour: false,
            tourStyle: {
                height: '0px',
            },
            showWallet: false,
            walletStyle: {
                height: '0px',
            },
            showMarketPlace: false,
            marketPlaceStyle: { height: '0px' },
            showAffiliate: false,
            affiliateStyle: {
                height: '0px',
            },
            navigations,
        };
    },
    computed: {
        ...mapGetters({
            isLogin: 'auth/loggedIn',
            Balance: 'wallet/Balance',
            UserInfo: 'info/UserInfo',
        }),
        walletGroup() {
            return (
                this.$route.name === 'Wallet' ||
                this.$route.name === 'StakingView' ||
                this.$route.name === 'P2PExchange'
            );
        },
        XNodeGroup() {
            return (
                this.$route.name === 'BuyXnode' ||
                this.$route.name === 'CorpoPackage' ||
                this.$route.name === 'BuyNFT' ||
                this.$route.name === 'XnodeInventory'
            );
        },
        XinServiceGroup() {
            return (
                this.$route.name === 'UtilityOptions' ||
                this.$route.name === 'Voucher' ||
                this.$route.name === 'MasterNodeMap'
            );
        },
        MarketPlaceGroup() {
            return !!this.$route?.matched?.find(
                (el) => el.name === 'MarketplaceView',
            );
        },
        AffiliateGroup() {
            return (
                this.$route.name === 'AmbassadorView' ||
                this.$route.name === 'Member'
            );
        },
    },
    methods: {
        logout() {
            this.$bvModal
                .msgBoxConfirm(this.$i18n.t('logoutConfirm'), {
                    title: this.$i18n.t('logout'),
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    dialogClass: 'myConfirm',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        this.$store.commit('auth/LOGOUT_SUCCESS');
                        this.$store.dispatch('merchant/disconnectSSE');
                        this.$store.commit('merchant/SET_IS_MERCHANT', false);
                    }
                });
        },

        handleClickOutside(e) {
            const myPop = document.querySelector('.links-pop');
            const myButton = document.querySelector('.menu-botan');

            if (
                myPop &&
                myButton &&
                !(myPop.contains(e.target) || myButton.contains(e.target))
            ) {
                this.showMenu = false;
            }
        },

        toggleScroll(ref, type) {
            switch (type) {
                case 'ecosystem':
                    if (this.showEcosystem) {
                        this.ecosystemStyle.height = `0px`;
                    } else {
                        this.ecosystemStyle.height = `${ref.scrollHeight}px`;
                    }

                    this.showEcosystem = !this.showEcosystem;
                    break;

                case 'service':
                    if (this.showService) {
                        this.serviceStyle.height = `0px`;
                    } else {
                        this.serviceStyle.height = `${ref.scrollHeight}px`;
                    }

                    this.showService = !this.showService;
                    break;

                case 'tour':
                    if (this.showTour) {
                        this.tourStyle.height = `0px`;
                    } else {
                        this.tourStyle.height = `${ref.scrollHeight}px`;
                    }

                    this.showTour = !this.showTour;
                    break;
                case 'wallet':
                    if (this.showWallet) {
                        this.walletStyle.height = `0px`;
                    } else {
                        this.walletStyle.height = `${ref.scrollHeight}px`;
                    }

                    this.showWallet = !this.showWallet;
                    break;
                case 'marketPlace':
                    if (this.showMarketPlace) {
                        this.marketPlaceStyle.height = `0px`;
                    } else {
                        this.marketPlaceStyle.height = `${ref.scrollHeight}px`;
                    }
                    this.showMarketPlace = !this.showMarketPlace;
                    break;
                case 'affiliate':
                    if (this.showAffiliate) {
                        this.affiliateStyle.height = `0px`;
                    } else {
                        this.affiliateStyle.height = `${ref.scrollHeight}px`;
                    }
                    this.showAffiliate = !this.showAffiliate;
                    break;
                default:
                    break;
            }
        },
    },

    mounted() {
        window.addEventListener('click', this.handleClickOutside);

        this.$router.afterEach(() => {
            this.showMenu = false;
        });

        this.heightEcosystem = this.$refs.ecosystemRef.offsetHeight;
    },

    unmounted() {
        window.removeEventListener('click', this.handleClickOutside);
    },
};
</script>

<style lang="scss" scoped>
.navigation {
    box-shadow: 0px -20px 20px -20px #0000001a;
    display: flex;
    padding: 8px;
    background-color: #107fb9;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;

    &::-webkit-scrollbar {
        width: 6px;
        display: block;
        padding-right: 5px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 12px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #0087cc;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #0087c8;
    }

    .logo {
        flex-basis: 40px;
        display: flex;
        align-items: center;
        max-width: 150px;
        margin: 0 auto;

        > img {
            display: block;
            margin: auto;
            width: 100%;
            padding: 5px;
        }
    }

    .links {
        display: flex;
        justify-content: center;
        gap: 6px;
        color: #c5c5c5;
        flex-grow: 1;

        a {
            color: inherit;
            border-radius: 6px;
            padding: 10px 15px;
            font-weight: 500;
            transition: 0.2s;
            display: grid;
            align-items: center;
            grid-template-columns: minmax(0, 30px) minmax(0, 0);
            display: none;

            &:hover {
                text-decoration: none;
            }

            &.active {
                background: #fff;
                color: #0087cc;
                font-weight: 700;
            }

            > svg {
                width: 100%;
            }

            > span {
                padding-left: 6px;
                overflow: hidden;
                transition: 0.2s;
            }

            @media (min-width: 1200px) {
                display: grid;

                &:hover {
                    grid-template-columns: minmax(0, 30px) minmax(0, 1fr);
                }
            }

            @media (min-width: 1200px) {
                grid-template-columns: minmax(0, 30px) minmax(0, 1fr);
            }

            &.new {
                position: relative;
                box-shadow: 0px 0px 3px 2px #d97706;
                z-index: 1;

                @media (min-width: 1200px) {
                    box-shadow: none;

                    &::before {
                        content: '';
                        position: absolute;
                        background-image: linear-gradient(
                            45deg,
                            #d97706,
                            #ea580c
                        );
                        width: 2em;
                        height: 1em;
                        mask-image: url('~@/assets/images/icons/new.svg');
                        mask-size: 200% 200%;
                        mask-position: center;
                        right: 0px;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: -1;
                    }
                }
            }
        }

        .ecosystem {
            cursor: pointer;
            display: none;

            .icon-ecosystem {
                display: grid;
                padding: 10px 15px;
                grid-template-columns: minmax(0, 30px) minmax(0, 1fr) minmax(
                        0,
                        20px
                    );
                font-weight: 700;

                p {
                    padding-left: 6px;
                    margin: 0;
                }

                .icon-right {
                    transition: all 0.3s ease-in-out;
                }

                .icon-right.active {
                    transform: rotate(90deg);
                }

                &.active {
                    background: #fff;
                    color: #0087cc;
                    border-radius: 6px;
                }
            }

            .ecosystem-list {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                gap: 6px;
                transition: height 0.3s ease;

                a {
                    grid-template-columns: 1fr;
                    padding-left: calc(15px + 30px + 6px);

                    &.active {
                        background: transparent;
                        color: #fff;
                    }

                    > span {
                        padding-left: 0px;
                    }
                }
            }

            @media (min-width: 1200px) {
                display: inline-block;
            }
        }
    }

    .logout {
        padding: 10px 15px;
        background: linear-gradient(to right, #b81717, #e99191);
        border-radius: 6px;
        color: #fff;
        display: flex;
        transition: 0.2s;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-right: 12px;

        &:hover {
            opacity: 0.8;
        }

        &:focus {
            outline: none;
        }
    }

    .links-pop {
        max-width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
        position: absolute;
        color: #656565;
        bottom: calc(100% + 5px);
        right: 0px;
        bottom: 0;
        height: 100vh;
        width: 100%;
        background-color: white;
        box-shadow: 0px 0px 10px 0px #0000001a;
        padding: 10px;
        border-radius: 6px 0 0 6px;
        overflow-y: scroll;

        .ecosystem:has(.ecosystem-list .active) .icon-ecosystem {
            background: #0087cc;
            color: #fff;
            border-radius: 6px;
        }

        .ecosystem:has(.ecosystem-list .active) .icon-ecosystem svg g path {
            fill: currentColor !important;
        }

        .navigation-mobie-wapper {
            min-height: 60%;
            max-height: 60%;
            overflow: auto;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 6px;
                display: block;
                padding-right: 5px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 12px;
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: #0087cc;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #0087c8;
            }
        }

        a {
            color: inherit;
            border-radius: 6px;
            padding: 6px 10px;
            font-weight: 500;
            transition: 0.2s;
            display: grid;
            grid-template-columns: minmax(0, 24px) minmax(0, auto);

            &:hover {
                text-decoration: none;
            }

            &.active {
                background-color: #fff;
                color: #0087cc;
            }

            &.active:has(svg) {
                background-color: #0087cc;
                color: #fff;
            }

            > svg {
                width: 100%;
            }

            > span {
                padding-left: 10px;
                overflow: hidden;
                white-space: nowrap;
            }

            @media (min-width: 1200px) {
                grid-template-columns: minmax(0, 30px) minmax(0, auto);
            }

            &.new {
                position: relative;
                z-index: 1;
                margin-right: 4px;

                &::before {
                    content: '';
                    position: absolute;
                    background-image: linear-gradient(45deg, #d97706, #ea580c);
                    width: 2em;
                    height: 1em;
                    mask-image: url('~@/assets/images/icons/new.svg');
                    mask-size: 200% 200%;
                    mask-position: center;
                    right: 0px;
                    top: 0;
                    z-index: -1;
                }
            }
        }

        transform-origin: bottom right;
        transform: scale(0);
        opacity: 0;
        transition: 0.2s;

        &.open {
            opacity: 1;
            transform: none;
        }

        .logout-pop {
            grid-column-start: 2;
            border: 1px solid red;
            border-radius: 6px;
            padding: 6px 12px;
            color: red;
            outline: none;
        }

        .ecosystem {
            cursor: pointer;

            .icon-ecosystem {
                display: grid;
                padding: 6px 10px;
                grid-template-columns: minmax(0, 30px) minmax(0, 1fr) minmax(
                        0,
                        20px
                    );

                p {
                    padding-left: 6px;
                    margin: 0;
                }

                .icon-right {
                    transition: all 0.3s ease-in-out;
                }

                .icon-right.active {
                    transform: rotate(90deg);
                }

                @media (min-width: 1200px) {
                    padding: 10px 15px;
                }
            }

            .ecosystem-list {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                gap: 6px;
                transition: height 0.3s ease;

                a {
                    grid-template-columns: 1fr;
                    padding-left: calc(10px + 30px + 6px);
                }
            }
        }

        .btn-close {
            display: flex;
            justify-content: flex-end;
            align-items: end;

            &:focus {
                outline: none;
            }
        }

        &::-webkit-scrollbar {
            width: 6px;
            display: block;
            padding-right: 5px;
        }

        &::-webkit-scrollbar-track {
            border-radius: 12px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: #0087cc;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #0087c8;
        }

        .menu-logo {
            display: block;
            margin: auto;
        }
    }

    .menu-botan {
        color: #0087c8;
        background-color: #fff;
        border-radius: 6px;

        > svg {
            width: 30px;
        }
    }

    @media (min-width: 1200px) {
        flex-direction: column;
        position: sticky;
        padding: 15px;
        top: 0px;
        height: 100vh;
        box-shadow: 20px 0px 20px -20px #0000001a;
        align-items: stretch;
        overflow-y: scroll;

        .logo {
            flex-basis: 80px;
            padding-bottom: 10px;

            > img {
                width: 100%;
            }
        }

        .links {
            flex-direction: column;
            justify-content: start;

            > a {
                padding: 10px 15px;
            }
        }

        .links-pop {
            display: none;
        }

        .menu-botan {
            display: none;
        }
    }
}
</style>
<style lang="scss">
.modal-dialog.myConfirm {
    .modal-content {
        header.modal-header {
            background: white;
            color: #656565;
        }

        div.modal-body {
            background: white;
            border-radius: 0;
        }

        .modal-footer {
            background-color: white;
            border: none;
        }
    }
}
</style>
