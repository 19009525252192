import { LIMIT_PAGINATION } from '../../components/marketplace/const';

const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        purchaseList: {
            results: [],
            page: 0,
            limit: 0,
            totalPages: 0,
            totalResults: 0,
        },
        myListToSell: null,
        mySoldList: null,
        paramsQuery: {
            sortBy: undefined,
            page: 1,
            limit: LIMIT_PAGINATION,
            minPrice: undefined,
            maxPrice: undefined,
            currency: undefined,
            itemName: undefined,
            itemGroup: undefined,
            itemNumber: undefined,
            itemType: undefined,
            itemId: undefined,
            itemStatus: ['AVAILABLE'],
        },
        marketStatistics: null,
        marketStatisticsDay: null,
        marketStatisticsSumDay: null,

        // Market history
        myMaketHistoryList: {
            results: [],
            page: 0,
            limit: 0,
            totalPages: 0,
            totalResults: 0,
        },
        myMaketHistoryQuery: {
            sortBy: undefined,
            page: 1,
            limit: LIMIT_PAGINATION,
            minPrice: undefined,
            maxPrice: undefined,
            currency: undefined,
            itemName: undefined,
            itemGroup: undefined,
            itemNumber: undefined,
            itemType: undefined,
            itemId: undefined,
        },

        selectedProduct: undefined,
    }),

    getters: {
        PurchaseList: (state) => state.purchaseList,
        ItemToSell: (state) => state.myListToSell,
        SoldItemList: (state) => state.mySoldList,
        getParamsQuery: (state) => state.paramsQuery,
        getMyMarketHistoryList: (state) => state.myMaketHistoryList,
        getMyMaketHistoryQuery: (state) => state.myMaketHistoryQuery,
        MarketStatistics: (state) => state.marketStatistics,
        MarketStatisticsDay: (state) => state.marketStatisticsDay,
        MarketStatisticsSumDay: (state) => state.marketStatisticsSumDay,
        SelectedProduct: (state) => state.selectedProduct,
    },

    actions: {
        req_getListPurchase: async ({ commit }, input) => {
            return axios
                .get('/market/bought', {
                    params: input,
                })
                .then((response) => {
                    if (input.isExport) {
                        if (response.data) {
                            window.location.href = response.data;
                            return;
                        }
                    }

                    commit('SET_PURCHASE', response.data);
                });
        },

        async getItemToSell({ commit }, params) {
            return axios.get('/market/item-to-sell', { params }).then((res) => {
                if (res.status) {
                    commit('SET_ITEM_TO_SELL', res.data);
                }
                return res;
            });
        },

        async getSoldItem({ state, commit }, params = { isExport: false }) {
            return axios
                .get('/market/sold', {
                    params: { ...state.paramsQuery, ...params },
                })
                .then((res) => {
                    if (params.isExport) {
                        if (res.data) {
                            window.location.href = res.data;
                        }
                    }

                    if (res.status) {
                        commit('SET_SOLD_ITEM', res.data);
                    }

                    return res;
                });
        },

        async onSellItem(context, params) {
            return axios.post('/market', params);
        },

        async getOTPSell(context, params) {
            return axios.get('/user/get-otp', { params });
        },

        async delCancelSellItem({ commit }, itemId) {
            return axios.delete(`/market/${itemId}`);
        },

        async getMarketStatistics({ commit }, params) {
            return axios
                .get('/market/statistics', {
                    params,
                })
                .then((res) => {
                    if (res.status) {
                        if (params && params.groupBy) {
                            commit('SET_MARKET_STATISTICS_DAY', res.data);
                            return;
                        }

                        commit('SET_MARKET_STATISTICS', res.data);
                    }
                });
        },

        async getMarketStatisticsSum({ commit }, params) {
            return axios
                .get('/market/statistics', {
                    params,
                })
                .then((res) => {
                    if (res.status) {
                        commit('SET_MARKET_STATISTICS_SUM_DAY', res.data);
                    }
                });
        },
    },

    mutations: {
        SET_PURCHASE(state, data) {
            const { results, page, limit, totalPages, totalResults } = data;

            state.purchaseList = {
                results,
                page,
                limit,
                totalPages,
                totalResults,
            };
        },
        SET_ITEM_TO_SELL(state, data) {
            state.myListToSell = data;
        },
        SET_SOLD_ITEM(state, data) {
            state.mySoldList = data;
        },
        UPDATE_PARAMS_QUERY(state, updatedParams) {
            state.paramsQuery = { ...state.paramsQuery, ...updatedParams };
        },

        // mymaket/history
        SET_MYMARKET_HISTORY(state, data) {
            state.myMaketHistoryList = data;
        },

        UPDATE_PARAMS_QUERY_MYMAKET_HISTORY(state, updatedParams) {
            state.myMaketHistoryQuery = {
                ...state.myMaketHistoryQuery,
                ...updatedParams,
            };
        },

        SET_MARKET_STATISTICS(state, data) {
            state.marketStatistics = data;
        },

        SET_MARKET_STATISTICS_DAY(state, data) {
            state.marketStatisticsDay = data;
        },

        SET_MARKET_STATISTICS_SUM_DAY(state, data) {
            state.marketStatisticsSumDay = data;
        },

        SET_PRODUCT(state, data) {
            state.selectedProduct = data;
        },
    },
};
