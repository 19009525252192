<template>
    <div class="mymarket-container">
        <div v-if="!isAddProductView" class="navigation">
            <router-link :to="{ name: 'MyProduct' }">{{
                $t('products')
            }}</router-link>
            <router-link :to="{ name: 'MarketHistory' }">{{
                $t('history')
            }}</router-link>
            <router-link :to="{ name: 'Balance' }">{{
                $t('balance')
            }}</router-link>
        </div>
        <router-view />
    </div>
</template>
<script>
export default {
    data() {
        return {
            route: this.$route.name,
        };
    },

    computed: {
        isAddProductView() {
            return this.$route.matched.some(
                (record) => record.name === 'AddProduct',
            );
        },
    },

    watch: {
        $route(to) {
            this.route = to.name;
        },
    },
};
</script>

<style lang="scss" scoped>
.mymarket-container {
    padding: 32px;

    .navigation {
        display: inline-flex;
        align-items: center;
        gap: 15px;
        color: #a4a4a4;

        a {
            padding: 10px 32px;
            border-radius: 100px;
            font-weight: 600;
            transition: 0.2s;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .active {
        background: #0087cc;
        color: #fff;
    }

    @media (max-width: 428px) {
        padding: 16px;

        .navigation {
            a {
                padding: 10px 12px;
            }
        }
    }
}
</style>
