<template>
  <div class="carousel-container">
    <div class="carousel-wrapper" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
      <div v-for="(item, index) in promotions" :key="index" class="carousel-slide">
        <img :src="item.banner" :alt="item.title" />
      </div>
    </div>

    <!-- Dots -->
    <div class="dots">
      <span v-for="(item, index) in promotions" :key="index" :class="{ 'active': index === currentSlide }"
        @click="goToSlide(index)"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PureVueCarousel',
  props: {
    promotions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentSlide: 0,
      autoPlayInterval: null,
    };
  },
  mounted() {
    this.startAutoPlay();
  },
  beforeDestroy() {
    this.stopAutoPlay();
  },
  methods: {
    nextSlide() {
      // Sử dụng promotions.length thay vì items.length
      this.currentSlide = (this.currentSlide + 1) % this.promotions.length;
    },
    prevSlide() {
      this.currentSlide = (this.currentSlide - 1 + this.promotions.length) % this.promotions.length;
    },
    goToSlide(index) {
      this.currentSlide = index;
    },
    startAutoPlay() {
      this.autoPlayInterval = setInterval(() => {
        this.nextSlide();
      }, 3000); // Chuyển slide mỗi 3 giây
    },
    stopAutoPlay() {
      if (this.autoPlayInterval) {
        clearInterval(this.autoPlayInterval);
      }
    },
  },
};
</script>

<style scoped>
.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 16px;
}

.carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease;
  width: 100%;
  border-radius: 16px;
}

.carousel-slide {
  flex: 0 0 100%;
  width: 100%;
  position: relative;
  border-radius: 16px;
}

.carousel-slide img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  display: block;
  border-radius: 16px;
}

/* Dots */
.dots {
  text-align: center;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.dots span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background: #bbb;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
}

.dots span.active {
  background: #333;
}
</style>