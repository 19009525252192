<template>
    <div class="card" @click="$emit('payment')">
        <div class="card-header">
            <img :src="product.itemData.photoUrl" alt="beeg" />
        </div>
        <div class="card-content">
            <div class="content-seller">
                {{ $t('seller') }}: #{{ product.userId }}
            </div>
            <!-- <div class="name" v-if="product.itemGroup === 'node'">
                {{ product.price.USDT }} USDT -
                {{ product.itemData.nodePerformance }} Hz/h
            </div> -->
            <div class="name">
                <div>
                    {{ product.itemData.name }}
                    <span v-if="product.itemGroup === 'node'">
                        {{
                            Number(
                                product.itemData.nodePerformance,
                            ).toLocaleString('en-US')
                        }}
                        Hz
                    </span>
                </div>
            </div>
            <div class="content-name">
                <template v-if="product.itemGroup === 'node'">
                    <span>
                        {{ getLifeTime(product.itemData.nodeExpired) }}
                    </span>
                </template>
                <template
                    v-else-if="
                        product.itemGroup === 'nft' &&
                        product.itemData.nftNumber
                    "
                >
                    {{ `${product.itemData.nftNumber}` }}
                </template>
            </div>
            <div class="content-price">
                <p>{{ $t('price') }}:</p>
                <div class="content-price__info">
                    <div class="info">
                        <span>{{
                            product.price.xinCoin.toLocaleString('en-US', {
                                maximumFractionDigits: 4,
                            })
                        }}</span>
                        <img src="@/assets/images/logo/xin.png" alt="Icon" />
                    </div>
                    <div class="info">
                        <span>{{
                            product.price.USDT.toLocaleString('en-US', {
                                maximumFractionDigits: 4,
                            })
                        }}</span>
                        <img src="@/assets/images/logo/usdt.png" alt="Icon" />
                    </div>
                </div>
            </div>
        </div>
        <button @click.stop="$emit('detail')" class="detail-button">
            <InfoIcon />
        </button>
    </div>
</template>
<script lang="ts">
import InfoIcon from '@/components/marketplace/icons/InfoIcon.vue';

export default {
    name: 'CartProduct',
    props: {
        product: {
            type: Object,
        },
    },
    components: {
        InfoIcon,
    },
};
</script>
<style lang="scss" scoped>
$white-color: #fff;
$border-color: #ddd;
$text-color: #a4a4a4;
$secondary-text-color: #999;
$primary-color: #0087cc;
$primary-hover-color: #0056b3;

.card {
    gap: 16px;
    border-radius: 16px;
    border: 1px solid #00d1ff;
    cursor: pointer;
    // min-width: 280px;
    position: relative;

    &:hover {
        transform: scale(1.03);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .card-header {
        width: 100%;
        height: 65%;
        min-height: 240px;
        max-height: 240px;
        border-radius: 16px;
        background: linear-gradient(209.87deg, #27abf2 1.29%, #070664 104.28%),
            no-repeat;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    .card-content {
        padding: 4px 16px 20px 16px;
        width: 100%;

        .content-seller {
            color: $text-color;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            letter-spacing: 0.05em;
            text-align: left;
            text-decoration-skip-ink: none;
            margin-bottom: 2px;
        }

        .name {
            font-weight: 600;
            text-decoration-skip-ink: none;
            color: $primary-color;
        }

        .content-name {
            font-size: 20px;
            font-weight: 600;
            text-decoration-skip-ink: none;
            color: $primary-color;
            height: 48px;
            line-height: 24px;
        }

        .content-price {
            display: flex;
            justify-content: space-between;

            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 0px;
                margin-top: 3px;
            }

            .content-price__info {
                display: flex;
                flex-direction: column;
                gap: 8px;
                text-align: right;

                .info {
                    display: flex;
                    align-items: center;
                    justify-content: end;

                    span {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        width: 100px;
                    }
                }

                .info:first-child {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 24px;
                    img {
                        width: 20px;
                    }
                }

                .info:last-child {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                }

                img {
                    margin-left: 4px;
                    object-fit: contain;
                    width: 18px;
                }
            }
        }
    }
}
.detail-button {
    padding: 0px;
    line-height: 1;
    color: $primary-color;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    transition: 0.2s;
    &:hover {
        color: $white-color;
    }
}
</style>
